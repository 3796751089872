<script setup lang="ts">
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_16_filled.svg";
import { useQuasar } from "quasar";
import {
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTrigger
} from "radix-vue";
import { onMounted, reactive, ref } from "vue";
import LoginSubPop from "./LoginSubPop.vue";
import {
  loginIdPw,
  loginSso,
  type Credentials,
  baseUrl
} from "../../api/backend";
import { useTokenStore } from "../../stores/token";
import { GoogleAuthAPI } from "../../configs/googleLogin";
import type { Token } from "../../api/types";
import { useStore } from "../../stores/store";

const googleAuth = new GoogleAuthAPI();
const tokenStore = useTokenStore();
const loginSubModel = ref(false);

const loginFailCount = ref(0);
const MAX_FAIL_COUNT = 3;

// 기본 BASE_URL 설정 2
// const BASE_URL = import.meta.env.VITE_BASE_URL || "https://anytalk.com:8443";
const BASE_URL = import.meta.env.VITE_BASE_URL;
// const BASE_URL = import.meta.env.VITE_BASE_URL || "https://chatweb.nsu.ac.kr";
// const BASE_URL = import.meta.env.VITE_BASE_URL || "http://220.68.189.120:8443";

onMounted(() => {
  // 페이지 로딩 시 기본 URL 설정
  baseUrl.value = localStorage.getItem("anytalk_api_base_url") || BASE_URL;
});

const handleSubmit = async () => {
  // if (credentials.username === "" || credentials.password === "") {
  //   $q.notify({
  //     message: "아이디와 비밀번호를 입력하세요.",
  //     color: "negative",
  //     timeout: 3000
  //   });
  //   return;
  // }

  if (credentials.username === "") {
    $q.notify({
      message: "아이디를 입력해주세요.",
      color: "negative",
      timeout: 3000
    });
    return;
  }

  if (credentials.password === "") {
    $q.notify({
      message: "비밀번호를 입력해주세요.",
      color: "negative",
      timeout: 3000
    });
    return;
  }

  try {
    const result = await loginIdPw(credentials);
    if (result) {
      await store.setUserFromLoginResult(result); // ✅ 로그인 후 사용자 정보 업데이트
      loginWithToken(result.access_token); // ✅ 토큰 전달
      loginFailCount.value = 0; // 로그인 성공 시 실패 횟수 초기화
    }
  } catch (error) {
    loginFailCount.value++; // 실패 횟수 증가
    $q.notify({
      message: "다시 시도해주세요.",
      color: "negative",
      timeout: 3000
    });
    if (loginFailCount.value >= MAX_FAIL_COUNT) {
      baseUrl.value = BASE_URL; // 3회 이상 실패 시 기본 URL로 변경
      localStorage.setItem("anytalk_api_base_url", BASE_URL); // 변경된 URL 저장
      $q.notify({
        message: "API 서버 URL이 초기화되었습니다.",
        color: "warning",
        timeout: 3000
      });
    }
  }
};

const closePop = () => {
  loginSubModel.value = false;
};

const props = defineProps({
  statusInput: Function
});

onMounted(() => {
  status.value = undefined;
  props.statusInput;
  // window.location.reload();
});

const $q = useQuasar();
const status = ref<boolean>();
const clearStatus = () => {
  status.value = undefined;
};

const isLogin = defineModel<boolean>({ required: true });

const credentials = reactive<Credentials>({
  username: "",
  password: ""
});

const store = useStore();

const handleGoogleSubmit = async () => {
  const user = await googleAuth.signIn();
  console.log("Google 로그인 정보", user.email, user.phoneNumber, user.uid);
  const result = await loginSso({
    uid: user.uid,
    // uid: user.email ?? null,
    email: user.email ?? null,
    nick: user.displayName ?? null,
    name: user.displayName ?? null,
    photo_url: user.photoURL ?? null,
    provider_id: user.providerId
  });

  if (result) {
    await store.setUserFromLoginResult(result); // 로그인 후 사용자 정보 업데이트
    loginWithToken(result.access_token); //토큰 전달
  }
};

const handleGuestSubmit = async () => {
  // guest 계정으로 자동 로그인 설정
  const guestCredentials: Credentials = {
    username: "guest",
    password: "2222"
  };

  try {
    // 로그인 API 호출
    const result = await loginIdPw(guestCredentials);

    if (result) {
      await store.setUserFromLoginResult(result);
      loginWithToken(result.access_token);

      // URL에 loginYn=1 파라미터 추가 (필요한 경우)
      const currentUrl = window.location.href;
      // const separator = currentUrl.includes("?") ? "&" : "?";
      // const newUrl = `${currentUrl}${separator}loginYn=1`;
      // const newUrl = `${currentUrl}${separator}`;
      const newUrl = `${currentUrl}`;

      // 현재 페이지를 새 URL로 교체 (히스토리에 기록하지 않고)
      window.history.replaceState({}, "", newUrl);

      // 성공 알림
      $q.notify({
        message: "비회원으로 접속합니다.",
        color: "positive"
        // timeout: 2000
      });
    }
  } catch (error) {
    console.error("비회원 로그인 실패:", error);
    $q.notify({
      message: "비회원 로그인에 실패했습니다.",
      color: "negative"
      // timeout: 3000
    });
  }
};

const loginWithToken = (token?: Token | string) => {
  console.log(token);
  if (token && typeof token === "object") {
    status.value = true;
    tokenStore.setToken((token as Token).access_token);
    fillInfo({ username: "", password: "" });
    isLogin.value = false;
    $q.notify({
      message: "로그인에 성공했습니다.",
      color: "positive",
      timeout: 3000
    });
  } else if (typeof token === "string") {
    // Handle the case when only access_token is passed
    status.value = true;
    tokenStore.setToken(token);
    fillInfo({ username: "", password: "" });
    isLogin.value = false;
    $q.notify({
      message: "로그인에 성공했습니다.",
      color: "positive",
      timeout: 3000
    });
  } else {
    $q.notify({
      message: "아이디 또는 비밀번호를 다시 확인해주세요",
      color: "negative",
      timeout: 3000
    });
    status.value = false;
  }
};

const JY_INFO: Credentials = {
  username: "최팀",
  password: "3333"
};
const MS_INFO: Credentials = {
  username: "민석",
  password: "2222"
};
const CH_INFO: Credentials = {
  username: "재영",
  password: "1111"
};
const userInfoList = [JY_INFO, MS_INFO, CH_INFO];

const fillInfo = (info: Credentials) => {
  $q.notify({
    message: `${info.username}님이 선택되었습니다.`,
    color: "positive",
    timeout: 3000
  });
  credentials.username = info.username;
  credentials.password = info.password;
  closePop();
};

// const logout = () => {
//   $q.notify("로그아웃 되었습니다.");
//   tokenStore.logOut();
// };
const customStyle = ref({ "--dialog-width": "40%" });

if (window.innerWidth <= 576) {
  customStyle.value["--dialog-width"] = "80%";
}

window.addEventListener("resize", () => {
  customStyle.value["--dialog-width"] =
    window.innerWidth <= 576 ? "80%" : "40%";
});
</script>

<template>
  <DialogPortal>
    <DialogOverlay class="DialogOverlay" />
    <!-- <div class="LoginContent"> -->
    <DialogContent
      class="LoginContent"
      :style="customStyle"
      :disableOutsidePointerEvents="false"
    >
      <div class="logoWrap">
        <img
          class="logoImg"
          src="/src/assets/anytalklogo.png"
          alt="anytalk logo"
        />
      </div>
      <div class="description">
        <div>
          애니톡과 함께 <span class="highlight">우리만의 AI챗봇</span>을 경험해
          보세요
        </div>
      </div>
      <div class="googleLoginWrap">
        <span class="googleLoginBtn" type="button" @click="handleGoogleSubmit">
          <img
            class="googleLogo"
            src="/src/assets/google.png"
            alt="anytalk logo"
          />
          <p>구글 로그인</p>
        </span>
      </div>
      <!-- <p class="udnerLine"></p> -->
      <span class="noLoginBtn" type="submit" @click="handleGuestSubmit"
        >비회원으로 이용하기</span
      >
      <p class="udnerLine"></p>
      <div class="idPwLoginWrap">
        <div class="idPwWrap">
          <!-- <form @submit.prevent="handleSubmit"> -->
          <form>
            <label for="username">
              <div class="inputTitle idBox">
                <p>아이디</p>
                <input
                  autocomplete="off"
                  class="InputDesc"
                  id="username"
                  type="text"
                  v-model="credentials.username"
                  placeholder="아이디를 입력해주세요"
                  required
                />
              </div>
            </label>
            <div>
              <label for="password">
                <div class="inputTitle">
                  <p>비밀번호</p>
                  <input
                    autocomplete="off"
                    class="InputDesc"
                    id="password"
                    type="password"
                    v-model="credentials.password"
                    placeholder="비밀번호를 입력해주세요"
                    required
                  />
                </div>
              </label>
            </div>
          </form>
        </div>
        <span tabindex="0" class="loginBtn" type="submit" @click="handleSubmit"
          >로그인</span
        >
      </div>

      <div
        :style="{
          display: 'flex',
          marginTop: 25,
          justifyContent: 'flex-end'
        }"
      ></div>
      <DialogClose
        style="background-color: white; box-shadow: none; margin-top: 10px"
        @click="clearStatus"
        class="IconButton"
        aria-label="Close"
        tabindex="2"
      >
        <CloseIcon />
      </DialogClose>
      <DialogRoot v-model:open="loginSubModel">
        <DialogTrigger
          style="
            background-color: white;
            border: none;
            box-shadow: none;
            margin: 10px 32px 0px 0px;
          "
          class="IconButton"
        >
          <img src="/src/assets/edit.png" alt="edit 버튼" class="editBtn" />
        </DialogTrigger>
        <LoginSubPop
          :closePop="closePop"
          :fillInfo="fillInfo"
          :handleGoogleSubmit="handleGoogleSubmit"
          :userInfoList="userInfoList"
          v-model="loginSubModel"
          @submit-login="handleSubmit"
        />
      </DialogRoot>
    </DialogContent>
    <!-- </div> -->
  </DialogPortal>
</template>

<style scoped lang="scss">
@use "../../styles/dialog";

.LoginContent {
  width: 35%;
  background-color: white;
  border-radius: 10px;
  box-shadow:
    hsla(206, 22%, 7%, 0.35) 0px 10px 38px -10px,
    hsla(206, 22%, 7%, 0.2) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  transform: translate(-50%, -50%);
  width: 80vw;
  /* max-width: 550px; */
  max-width: 380px;
  max-height: 80vh;
  padding: 35px 40px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.LoginContent:focus {
  outline: none;
}

.logoWrap {
  /* width: 400px; */
  display: flex;
  justify-content: center;
}
.logoImg {
  width: 240px;
  padding: 30px 0px 40px 0px;
}

.description {
  width: 100%;
  font-weight: bold;
  font-size: 15px;
  font-weight: 500;
  color: #3b3b3b;
  margin-bottom: 15px;
}
.highlight {
  color: #2e9e76;
  font-weight: 600;
}
.idPwLoginWrap {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.idPwWrap {
  margin-top: 13px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex: 2.5;
}

.idBox {
  margin-bottom: 10px;
}
.inputTitle {
  display: flex;
  font-size: 14px;
  color: #5b5b5b;
  // margin-bottom: 3px;
  align-items: center;
}

.inputTitle p {
  text-align: center;
  min-width: 50px;
  height: 2.3rem;
  align-content: center;
}
.inputTitle input {
  margin-left: 10px;
}

.InputDesc {
  // margin-bottom: 10px;
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  line-height: 1;
  height: 2.3rem;
  color: #5b5b5b;
  border: 0.5px solid #b2b2b2;
  border-radius: 4px;
}

.InputDesc::placeholder {
  color: #bbbbbb;
}

.InputDesc::-webkit-input-placeholder {
  color: #bbbbbb;
}

.InputDesc:-ms-input-placeholder {
  color: #bbbbbb;
}

.loginBtn {
  flex: 0.75;
  /* height: 2.3rem; */
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  background-color: #03ceae;
  color: white;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  align-content: center;
  margin: 13px 0 0px 0;
}

.loginBtn:hover {
  background-color: #02b79b;
}

.noLoginBtn {
  height: 2.3rem;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #02b79b;
  // color: white;
  color: #02b79b;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  align-content: center;
  margin: 10px 0 23px 0;
}

.noLoginBtn:hover {
  background-color: #e8f8f6;
}

.googleLoginWrap {
  margin-bottom: 13px;
  :hover {
    background-color: #d7d7d7;
    color: #393939;
  }
}
.googleLoginBtn {
  height: 2.3rem;
  font-size: 16px;
  width: 100%;
  /* background-color: white; */
  background-color: rgb(236, 236, 236);
  border: 0.5px solid #949494;
  color: #696969;
  /* color: white; */
  /* font-weight: bold; */
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  align-content: center;
  display: flex;
  justify-content: center;
  & p {
    align-content: center;
    font-weight: 700;
  }
}

.googleLogo {
  object-fit: contain;
  align-items: center;
  padding: 6px;
}
.Form {
  width: 100%;
}

.FieldContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
}

.FieldGroup {
  justify-content: stretch;
}

.Label {
  width: 60px;
}

.SubmitContainer {
  text-align: center;
  width: 100%;
}

.SubmitButton {
  height: 100%;
  min-width: max-content;
}

.loginInfoItem {
  width: min(200px, 100%);
  display: flex;
  justify-content: space-between;
}

.loginStatus {
  margin-top: 5px;
  overflow: hidden;
  text-align: center;
}
.editBtn {
  width: 20px;
  opacity: 30%;
}

.udnerLine {
  width: 100%;
  /* border: 0.5px dotted #b2b2b2; */
  border: 0.5px dashed #b2b2b2;
}

/* 반응형 디자인 적용 */
@media (max-width: 576px) {
  .LoginContent {
    padding: 20px 30px;
    height: auto;
    max-height: 75vh; /* Ensure max-height is 75vh for smaller screens */
  }

  .logoImg {
    width: 220px;
    padding: 30px 10px 40px 10px;
  }

  .description {
    font-size: 12px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  .inputTitle {
    font-size: 12px;
  }

  .InputDesc {
    font-size: 12px;
    height: 1.8rem;
    // margin-bottom: 8px;
  }

  .loginBtn {
    font-size: 14px;
    height: 1.8rem;
  }

  .noLoginBtn {
    font-size: 14px;
    height: 1.8rem;
  }

  .googleLoginBtn {
    font-size: 14px;
    height: 1.8rem;
  }

  .editBtn {
    width: 18px;
  }

  .idBox {
    margin-bottom: 6px !important;
  }
}

/* @media (min-width: 1200px) {
  .LoginContent {
    width: 30%;
    padding: 40px 40px 50px 40px;
    height: auto;
    max-height: 75vh;
  }

  .logoImg {
    width: 75%;
    padding: 50px 15px 60px 15px;
  }

  .description {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .inputTitle {
    font-size: 18px;
  }

  .InputDesc {
    font-size: 18px;
    height: 3.2rem;
  }

  .loginBtn {
    font-size: 22px;
    height: 3rem;
  }

  .googleLoginBtn {
    font-size: 22px;
    height: 3rem;
  }

  .editBtn {
    width: 70%;
  }
} */
</style>
