<!-- MainHeader -->
<script setup lang="ts">
import alarmBell from "@fluentui/svg-icons/icons/alert_on_20_regular.svg";
import NavigationIcon from "@fluentui/svg-icons/icons/navigation_24_regular.svg";
import editIcon from "@fluentui/svg-icons/icons/settings_24_regular.svg";
import signOut from "@fluentui/svg-icons/icons/sign_out_24_regular.svg";
import { DialogRoot, DialogTrigger } from "radix-vue";
import { computed, onMounted, onBeforeUnmount, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import alimPop from "../../popups/alimListPop.vue";
import { useStore } from "../../stores/store";
import { useTokenStore } from "../../stores/token";
import SidebarTrigger from "../common/sidebar/SidebarTrigger.vue";
import LoginDialog from "./LoginDialog.vue";
import LoginDialogTemp from "./LoginDialogTemp.vue";
import { baseUrl, initBaseUrl, localUrl } from "../../api/backend";
import { useAnimatedSplitter } from "../../pages/main/animatedSplitter";
import { breakpoints } from "../../styles/responsive";
import Homepage from "@fluentui/svg-icons/icons/home_24_regular.svg";
import { useLocalStorage } from "@vueuse/core";
import MainBox from "../common/MainBox.vue";
import ChevronDownIcon from "@fluentui/svg-icons/icons/chevron_down_24_regular.svg";
import ChevronUpIcon from "@fluentui/svg-icons/icons/chevron_up_24_regular.svg";
import { sidebarKey } from "../common/sidebar/SidebarRoot.vue";
import { inject } from "vue";

import arrowUp from "@/assets/arrowUp.png";
import arrowDown from "@/assets/arrowDown.png";

const store = useStore();
const tokenStore = useTokenStore();
const loginModel = ref(!tokenStore.isLogin);
const router = useRouter();

// const BASE_URL = ref("https://api.anytalk.kr:8082");
// const BASE_URL = import.meta.env.VITE_BASE_URL || "https://anytalk.com:8443";
const BASE_URL = import.meta.env.VITE_BASE_URL;
// const BASE_URL = import.meta.env.VITE_BASE_URL || "https://chatweb.nsu.ac.kr";
// const BASE_URL = import.meta.env.VITE_BASE_URL || "http://220.68.189.120:8443";

// const baseUrl = useLocalStorage("anytalk_api_base_url", BASE_URL);

// const isCustomBaseUrl = computed(() => baseUrl !== "https://anytalk.com:8443");

const isCustomBaseUrl = computed(
  // () => baseUrl.value !== "https://anytalk.com:8443"
  () => baseUrl.value !== BASE_URL
  // () => baseUrl.value !== "https://chatweb.nsu.ac.kr"
  // () => baseUrl.value !== "http://220.68.189.120:8443"
);

const moveToMain = () => {
  // router.push("/");
  router.push("/talk");
};

// const isChannelPage = computed(() => {
//   return route.path.startsWith("/talk/channel");
// });

const isFromDirectLink = computed(() => {
  // URL에 chKey 파라미터가 있으면 직접 링크로 간주
  console.log(
    "first",
    !!route.query.chKey || route.path.includes("/talk/channel")
  );
  return !!route.query.chKey || route.path.includes("/talk/channel");
});

const isObserver = computed(() => {
  console.log("store.myInfo?.observer_yn", store.myInfo?.observer_yn);
  // return store.myInfo?.observer_yn === 1;
  return store.myInfo?.observer_yn === "1";
});

const showChannelSettingsIcon = computed(() => {
  console.log("============== store.myInfo?.observer_yn", isObserver.value);
  // if (isObserver.value) {
  //   return true; // ✅ observer이면 무조건 보이게 설정
  // }
  // return true;
  if (store.currentChannel?.channel_key === 85) {
    return true;
  }
  return !isFromDirectLink.value; // ✅ observer가 아니면, 직접 링크가 아닐 때만 보이도록 설정
});

const status = ref("");

// onMounted(() => {
//   console.log("URL", BASE_URL);
//   statusInput();
// });

onMounted(() => {
  console.log("BASE_URL:", BASE_URL);
  console.log("baseUrl.value:", baseUrl.value);
});

// const BASE_URLS = import.meta.env.VITE_BASE_URLS?.split(",");

// const statusInput = () => {
//   if (BASE_URLS?.includes(BASE_URL)) {
//     status.value = "";
//     console.log("값은?", status.value);
//   } else {
//     status.value = "개발환경";
//     console.log("값은?", status.value);
//   }
// };
const statusInput = () => {
  if (
    BASE_URL == "https://anytalk.com:8443" ||
    BASE_URL == "https://anytalk.com:8443/talk"
  ) {
    // if (
    //   BASE_URL == "https://chatweb.nsu.ac.kr" ||
    //   BASE_URL == "https://chatweb.nsu.ac.kr/talk"
    // ) {
    status.value = "";
    console.log("값은?", status.value);
  } else {
    status.value = "개발환경";
    console.log("값은?", status.value);
  }
};

const alimPopYn = ref(false);
const openAlimPop = () => {
  alimPopYn.value = true;
  console.log("URL", BASE_URL);
};
const closeAlimPop = () => {
  alimPopYn.value = false;
};

const gotoMypage = () => {
  router.push("/talk/my");
};

const logout = () => {
  const chanIndex = store.allChannelList.findIndex(
    (item) => item.channel_key === 85
  );
  if (chanIndex) {
    store.currentChannel = store.allChannelList[chanIndex];
    router.push("/talk");
    // console.log("로그인하고 슬라이드 끄기");
  }
  tokenStore.logOut();
};
const goToHomepage = () => {
  router.push("/");
};

// 관리자 여부 확인
const isAdmin = computed(() => {
  const currentChannel = store.currentChannel;
  const myInfo = store.myInfo;

  if (!currentChannel || !myInfo) {
    return false; // 기본값으로 false를 반환하거나, 적절한 값을 반환
  }
  // const nsuYn = ref(false);
  // if (store.myInfo?.user_key == 27 && currentChannel.channel_key == 250) {
  //   return (nsuYn.value = true);
  // }
  return (
    currentChannel.auth_type === "A" ||
    currentChannel.auth_type === "E" ||
    myInfo.observer_yn == "1"
  );
});

// 홈페이지 여부 확인
const isMainPage = computed(() => {
  return route.path === "/"; // 메인 페이지(`/`) 여부 확인
});

const route = useRoute();

// 남서울대 페이지 여부 확인
const isNsuPage = computed(() => {
  return (
    route.path.includes("/talk/channel?chKey=250") ||
    store.currentChannel?.channel_key == "250"
  ); // 남서울대 라우트 경로에 맞게 수정
});

const isNsu = computed(() => store.myInfo?.nick === "nsu");

let channelPopup: Window | null = null;

const openChannelManagement = () => {
  // const width = 800;
  // const height = 600;
  const width = 1210;
  const height = 860;
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;

  // 기존 팝업이 열려 있는지 확인
  if (channelPopup && !channelPopup.closed) {
    channelPopup.focus(); // 기존 창을 활성화
  } else {
    // 새로운 팝업 창 열기
    channelPopup = window.open(
      "/channelManage",
      "channelPopup", // 고유한 이름을 지정하여 같은 창을 재사용
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
    );

    if (channelPopup) {
      channelPopup.focus();

      setTimeout(() => {
        channelPopup?.postMessage(
          {
            type: "LOCAL_STORAGE_DATA",
            data: {
              anytalk_user_info: localStorage.getItem("anytalk_user_info"),
              anytalk_jwt_token: localStorage.getItem("anytalk_jwt_token"),
              anytalk_api_base_url: localStorage.getItem(
                "anytalk_api_base_url"
              ),
              last_channel_key: localStorage.getItem("last_channel_key")
            }
          },
          window.location.origin
        );
      }, 1000);

      // 팝업이 로드된 후 정확한 크기 설정
      channelPopup.onload = () => {
        channelPopup?.resizeTo(width, height);
      };

      // 팝업이 닫히면 변수 초기화
      channelPopup.onbeforeunload = () => {
        channelPopup = null;
      };
    } else {
      alert("팝업 차단이 활성화되어 있습니다!");
    }
  }
};
const isNsuUser = computed(() => {
  return (
    (route.path.includes("/talk/channel?chKey=250") ||
      store.currentChannel?.channel_key == "250") &&
    store.myInfo?.nick == "nsu"
  );
});

// const isMainBoxVisible = ref(true);
const isMainBoxVisible = ref(false);

const toggleMainBox = (event: MouseEvent) => {
  event.stopPropagation(); // 이벤트 전파 방지 (외부 클릭 이벤트가 실행되지 않도록)
  isMainBoxVisible.value = !isMainBoxVisible.value;
  window.dispatchEvent(
    new CustomEvent("toggle-mainbox", {
      detail: { isVisible: isMainBoxVisible.value }
    })
  );
};

const closeMainBox = () => {
  isMainBoxVisible.value = false;
  window.dispatchEvent(
    new CustomEvent("toggle-mainbox", {
      detail: { isVisible: isMainBoxVisible.value }
    })
  );
};

const context = inject(sidebarKey);

const isMobile = ref(false);

const mainBoxRef = ref<HTMLElement | null>(null);
const toggleButtonRef = ref<HTMLElement | null>(null);
// MainBox 외부 클릭 시 닫기
// MainBox 외부 클릭 시 닫기
const closeMainBoxOutside = (event: MouseEvent) => {
  // MainBox가 열려 있지 않으면 실행할 필요 없음
  if (!isMainBoxVisible.value) return;

  // `mainBoxRef.value`가 존재하고, `contains` 메서드를 사용할 수 있는지 확인 후 실행
  if (
    mainBoxRef.value instanceof HTMLElement &&
    mainBoxRef.value.contains(event.target as Node)
  ) {
    return;
  }

  // `toggleButtonRef.value`도 동일한 방식으로 체크
  if (
    toggleButtonRef.value instanceof HTMLElement &&
    toggleButtonRef.value.contains(event.target as Node)
  ) {
    return;
  }

  console.log("MainBox 닫기");
  closeMainBox();
};

onMounted(() => {
  document.addEventListener("click", closeMainBoxOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", closeMainBoxOutside);
});

watch(
  () => tokenStore.isLogin,
  (isLoggedIn) => {
    if (isLoggedIn) {
      loginModel.value = false;
    }
  }
);

const isSahthisYn = computed(
  () =>
    store.currentChannel?.channel_key === 397 && store.myInfo?.user_key === 75
);
</script>
<template>
  <!-- <SidebarTrigger
    v-if="tokenStore.isLogin && !isNsuUser"
    style="margin-top: -15px !important"
    class="sidebarTrigger siedBtn"
    ><NavigationIcon
  /></SidebarTrigger> -->
  <!-- <SidebarTrigger
    v-if="tokenStore.isLogin && store.myInfo?.observer_yn == 1"
    style="margin-top: -15px !important"
    class="sidebarTrigger siedBtn"
    ><NavigationIcon
  /></SidebarTrigger> -->
  <!-- <div v-if="tokenStore.isLogin && store.myInfo?.observer_yn == 1">
    <SidebarTrigger
      style="margin-top: -15px !important"
      class="sidebarTrigger siedBtn"
      ><NavigationIcon
    /></SidebarTrigger>
  </div> -->
  <div
    v-if="
      tokenStore.isLogin &&
      store.myInfo?.user_key !== 102 &&
      showChannelSettingsIcon
    "
  >
    <!-- <div v-if="tokenStore.isLogin"> -->
    <SidebarTrigger
      style="margin-top: -15px !important"
      class="sidebarTrigger siedBtn"
      ><NavigationIcon
    /></SidebarTrigger>
  </div>
  <button
    v-else-if="!tokenStore.isLogin && !isNsuUser"
    @click="goToHomepage"
    class="sidebarTrigger logout-button homeBtn"
  >
    <Homepage />
  </button>
  <div v-if="isNsuUser"></div>
  <header class="headerbox flexJustify">
    <!-- <img
      @click="moveToMain"
      class="logo cursorP"
      src="/src/assets/logo.png"
      alt="anytalk logo"
    /> -->
    <!-- <img
      @click="moveToMain"
      class="logo cursorP"
      src="/src/assets/anytalk.png"
      alt="anytalk logo"
    /> -->
    <div class="titleBox" @click="toggleMainBox">
      <!-- <div class="subtitle">채널기반 성장형 AI 챗봇</div> -->
      <!-- <div class="subtitle">채널성장형 AI 챗봇</div>
      <div class="copyright">ⓒ 2024 by 새움소프트</div> -->
      <!-- 
      <div @click="moveToMain" class="nsuTitle">
        <img
          @click="moveToMain"
          class="logo cursorP"
          src="/src/assets/anytalk.png"
          alt="anytalk logo"
        /> -->

      <div class="nsuTitle">
        <img
          class="logo cursorP"
          src="/src/assets/anytalk.png"
          alt="anytalk logo"
        />
        {{ store.currentChannel?.name || "채널을 선택해주세요." }}
      </div>
      <p
        v-if="!isSahthisYn"
        class="channelKey"
        style="font-size: 10px; color: gray"
      >
        {{ store.currentChannel?.channel_key }}
      </p>
      <!-- <div class="toggle-button" @click="toggleMainBox" tabindex="0">
        <component :is="isMainBoxVisible ? ChevronUpIcon : ChevronDownIcon" />
      </div> -->
      <!-- <div class="toggle-button" @click="toggleMainBox" tabindex="0"> -->
      <div class="toggle-button" tabindex="0">
        <!-- <img
          class="toggleImg"
          :src="
            isMainBoxVisible
              ? 'src/assets/arrowUp.png'
              : 'src/assets/arrowDown.png'
          "
          alt="Toggle Icon"
        /> -->
        <img
          class="toggleImg"
          :src="isMainBoxVisible ? arrowUp : arrowDown"
          alt="Toggle Icon"
        />
      </div>
    </div>
  </header>
  <MainBox v-if="!isMainPage && !context?.enabled.value" ref="mainBoxRef" />
  <div class="mainboxOverlay"></div>
  <DialogRoot v-model:open="alimPopYn">
    <alimPop />
  </DialogRoot>
  <DialogRoot v-model:open="loginModel">
    <div class="loginButton loginInfoContainer">
      <template v-if="tokenStore.isLogin">
        <div class="statusWrap">
          <span>{{ status }}</span>
        </div>
        <!-- <div class="fontBg cursorP alarmBell" @click="openAlimPop">
          <alarmBell />
        </div> -->
        <div class="baseUrlWrap">
          <div class="baseUrlTopWrap">
            <!-- 관리 페이지 이동 -->
            <button
              v-if="
                isAdmin && tokenStore.isLogin && store.myInfo?.user_key !== 102
              "
              class="logout-button edit"
              @click="openChannelManagement"
            >
              <editIcon />
              <!-- <img src="/src/assets/edit.png" alt="edit 버튼" class="editBtn" /> -->
            </button>
            <div @click="gotoMypage" class="userNick cursorP">
              {{ store.myInfo?.nick ?? "" }}님
            </div>
            <!-- <button @click="tokenStore.logOut()" class="fillHeight"> -->
            <!-- <button
              v-if="!isNsuPage || store.myInfo?.observer_yn == 1"
              @click="logout"
              class="fillHeight logout-button"
            > -->
            <button
              v-if="!isSahthisYn"
              @click="logout"
              class="fillHeight logout-button"
            >
              <!-- 로그아웃 -->
              <signOut />
            </button>
          </div>

          <p v-if="isCustomBaseUrl">개발환경 : {{ baseUrl }}</p>
        </div>
      </template>
      <!-- <DialogTrigger v-else class="loginBtn fillHeight">로그인</DialogTrigger> -->
      <DialogTrigger
        style="align-self: anchor-center; margin-right: 5px"
        v-else
        class="fillHeight loginBtnMobile"
        >로그인</DialogTrigger
      >
    </div>
    <LoginDialog :statusInput="statusInput" />
    <!-- <LoginDialogTemp v-model="loginModel" :statusInput="statusInput" /> -->
  </DialogRoot>
</template>
<style lang="scss">
@use "../../styles/responsive";
@use "./header";

:root {
  $headerMargin: 2 * header.$headerMargin;
  $headerBoxMargin: 2 * header.$headerBoxMargin;
  $headerLineHeight: (header.$subTitleFontSize + header.$copyRightFontSize) *
    1.5;

  --header-logo-height: 32px;
  --header-logo-half-height: calc(var(--header-logo-height) / 2);
  --header-height: #{calc(var(--header-logo-height) + $headerMargin)};
  @include responsive.tablet {
    --header-logo-height: 52px;
    --header-height: #{calc(
        $headerLineHeight + #{$headerMargin + $headerBoxMargin + 1px}
      )};
  }
}
</style>
<style scoped lang="scss">
@use "../../styles/responsive";
@use "./header";

.loginInfoContainer {
  display: flex;
  align-items: baseline;
}
.userNick {
  margin-inline: 10px;
}
.baseUrlWrap {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: center;
}
.baseUrlWrap p {
  font-size: 9px;
  color: gray;
}
.baseUrlTopWrap {
  display: flex;
  align-items: center;
}

.fillHeight {
  height: 100%;
  /* margin-top: -15px !important; */
  min-height: 25px !important;
}

.loginBtnMobile {
  @media (max-width: 575px) {
    margin-top: 25px !important;
  }
}

.loginBtn {
  background-color: rgb(84, 84, 84);
  color: white;
  font-weight: 700;
}

.headerbox {
  flex-direction: row;
  min-height: 60px !important;
  // width: calc(100% - 300px);
  margin: 0 auto;
  justify-content: flex-start;
  position: relative;
}

.logo {
  height: 50%;
  flex-shrink: 0;
  width: auto;
  align-self: center;
  /* height: var(--header-logo-height); */
  /* transform: translate(-10px, 5px); */
  @include responsive.tablet {
    transform: translateY(0px);
  }
}

.titleBox {
  flex: 1; // 이거 필수
  min-width: 0; // 이거도 필수
  overflow: hidden; // 내부 요소가 넘치지 않도록

  /* display: none; */
  font-size: 10px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  // max-width: 100%;
  gap: 8px;
  @include responsive.tablet {
    display: flex;
    /* flex-direction: column; */
    flex-direction: row; // column에서 row로 변경
    align-items: center;
    justify-content: center;
  }
}

.subtitle {
  font-size: header.$subTitleFontSize;
  font-weight: 600;
}

.copyright {
  display: inline;
  font-size: header.$copyRightFontSize;
  font-weight: 500;
  margin-top: 1px;
}

.statusWrap {
  color: #9d9d9d;
  margin-right: 10px;
}

@media (max-width: 575px) {
  .toggle-button {
    /* display: none !important; */
    width: 20px !important;
    display: none !important;
  }

  .titleBox {
    /* gap: 1px !important; */
  }

  .toggleImg {
    width: 20px;
    height: 20px;
    object-fit: contain;
    opacity: 0.3;
    /* margin-left: 10px; */
  }

  .baseUrlWrap {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-self: center;
    margin-top: 30px !important;
  }
  .baseUrlWrap p {
    font-size: 9px;
    color: gray;
  }
}

@media (max-width: 787px) {
  .channelKey {
    // display: none;
    display: inline;
    font-size: 9px !important;
  }

  .alarmBell {
    display: none;
  }
  .userNick {
    display: none;
  }
  .logo {
    display: none;
  }
  .titleBox {
    align-content: center;
  }

  .nsuTitle {
    font-size: 1.2rem !important;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px !important;
    /* max-width: 70% !important; */
    min-width: 50% !important;
  }
}

.nsuTitle {
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; /* 최대 너비를 조정 */
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 50% !important;

  // 💡 이 부분이 핵심입니다
  flex-shrink: 1; // 공간이 부족할 때 줄어들 수 있도록
  min-width: 0; // flex 안에서 overflow가 적용되도록
  display: block; // 또는 inline-block / flex (flex일 경우 추가 처리 필요)
  overflow: hidden; // 넘치는 텍스트 감추기
  text-overflow: ellipsis; // 넘치는 텍스트를 ...으로 표시
  white-space: nowrap; // 줄 바꿈 방지
}

.nsuTitle span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  min-width: 180px !important;
}

.siedBtn {
  border: none !important;
  box-shadow: none !important;
  margin-top: -15px !important;

  @media (max-width: 575px) {
    margin-top: 0px !important;
    top: 13px;
  }
}

.homeBtn {
  margin-top: -13px !important;
  @media (max-width: 575px) {
    margin-top: 0px !important;
  }
}

.logout-button {
  /* all: unset; // 모든 기본 스타일 제거 */
  cursor: pointer;
  min-height: 25px !important;
  background-color: white !important;
  border: none !important;
  box-shadow: none !important;
  /* display: flex; */
  /* align-items: center; */
  /* background: transparent !important; */
  /* border: none;
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    background: transparent !important;
    outline: none;
  } */
}

.editBtnWrap {
  width: 25px !important;
  height: 25px !important;
  background-color: none !important;
}

.editBtn {
  width: 100% !important;
  height: 100% !important;
  background-color: none !important;
}
.toggleImg {
  width: 40px;
  height: 40px;
  object-fit: contain;
  opacity: 0.3;
}

.toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 4px 0px;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .toggle-label {
    font-size: 14px;
    color: #666;
  }
}
</style>
