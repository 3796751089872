// backend.ts

import { createQueryString } from "@toss/utils";
import { useLocalStorage } from "@vueuse/core";
import { useStore } from "../stores/store";
import { useTokenStore } from "../stores/token";
import type {
  AuthUser,
  Channel,
  KmsKnow,
  KmsRepoImage,
  Know,
  KnowCheck,
  KnowOrg,
  Msg,
  MsgCheck,
  ResDomain,
  ResWebPage,
  Room,
  RoomUser,
  Token,
  User
} from "./types";

// const BASE_URL = import.meta.env.VITE_BASE_URL || "https://anytalk.com:8443";
export const BASE_URL = import.meta.env.VITE_BASE_URL;
// const BASE_URL = import.meta.env.VITE_BASE_URL || "https://chatweb.nsu.ac.kr";
// import.meta.env.VITE_BASE_URL || "https://chatweb.nsu.ac.kr";
// import.meta.env.VITE_BASE_URL || "http://220.68.189.120:8443";

export const baseUrl = useLocalStorage("anytalk_api_base_url", BASE_URL);

export function initBaseUrl() {
  const cachedBaseUrl = localStorage.getItem("anytalk_api_base_url");
  // baseUrl.value = BASE_URL;
  // baseUrl.value = "https://api.anytalk.kr:8082";
  // baseUrl.value = "https://anytalk.com:8000";
  // baseUrl.value = "https://anytalk.com:8443";
  baseUrl.value = BASE_URL;
  // baseUrl.value = "https://chatweb.nsu.ac.kr";
  // baseUrl.value = "http://220.68.189.120:8443";
  console.log("baseUrl.value = " + baseUrl.value);

  if (cachedBaseUrl && cachedBaseUrl !== baseUrl.value) {
    console.log(`캐시된 API URL 감지: ${cachedBaseUrl}`);
    console.log(`온라인 API URL로 업데이트: ${baseUrl.value}`);
    localStorage.setItem("anytalk_api_base_url", baseUrl.value);
  }
}
export function localUrl() {
  baseUrl.value = "https://localhost:8443";
  console.log("baseUrl.value = " + baseUrl.value);
}

// == Channel ==================================================================
// == GET =======================
export interface ResChannelList {
  channel_list: Channel[];
  auth_user_list: AuthUser[];
}
export interface ReqGetChannelList {
  channel_key?: number;
  channel_name?: string;
  own_user_key?: number;
  auth_user_key?: number;
  user_key?: number;
}

// 채널목록 불러오기
export async function getChannelList(param: ReqGetChannelList = {}) {
  // const tokenStore = useTokenStore();
  const response = await fetch(
    // `${baseUrl.value}/channel?channel_key=${channelKey}`,
    `${baseUrl.value}/channel/${createQueryString(param)}`,
    {
      mode: "cors"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // headers: {
      //   accept: "application/json",
      //   ...tokenStore.createHeader()
      // }
    }
  );

  if (response.ok) {
    // 추가 성공 처리
    console.log("채널 목록 로드 성공");
    console.log("고정 Url값=============", import.meta.env.VITE_BASE_URL);
    console.log("현재 BaseUrl값=============", baseUrl.value);
    return (await response.json()) as AuthChannel[];
  } else {
    // 추가 실패 처리
    console.error("채널 목록 로드 실패:", response.statusText);
    console.log("고정 Url값=============", import.meta.env.VITE_BASE_URL);
    console.log("현재 BaseUrl값=============", baseUrl.value);
  }
}

// 안쓰는 것 -> 내 채널 정보 불러오기
// export async function getMyChannelList() {
//   const tokenStore = useTokenStore();
//   const response = await fetch(`${baseUrl.value}/channel/my`, {
//     mode: "cors",
//     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//     // @ts-ignore
//     headers: {
//       accept: "application/json",
//       ...tokenStore.createHeader()
//     }
//   });

//   if (response.ok) {
//     // 추가 성공 처리
//     console.log("채널 목록 로드 성공");
//     return (await response.json()) as ResChannelList;
//   } else {
//     // 추가 실패 처리
//     console.error("채널 목록 로드 실패:", response.statusText);
//     console.log(baseUrl.value);

//     if (response.status === 401) {
//       tokenStore.logOut();
//     }
//   }
// }

// 안쓰는 것
// export async function getAllChannelList() {
//   const tokenStore = useTokenStore();
//   const response = await fetch(`${baseUrl.value}/channel/all`, {
//     mode: "cors",
//     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//     // @ts-ignore
//     headers: {
//       accept: "application/json",
//       ...tokenStore.createHeader()
//     }
//   });

//   if (response.ok) {
//     // 추가 성공 처리
//     console.log("채널 목록 로드 성공");
//     return (await response.json()) as AuthChannel[];
//   } else {
//     // 추가 실패 처리
//     console.error("채널 목록 로드 실패:", response.statusText);
//     console.log(baseUrl.value);

//     if (response.status === 401) {
//       tokenStore.logOut();
//     }
//   }
// }

// == POST/PUT/DELETE =======================
// 채널 생성
export async function createChannel(
  name: string,
  description: string,
  secure_str4: string,
  secure_yn: number,
  kms_yn: number
) {
  const store = useStore();
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/channel`, {
    method: "POST",
    mode: "cors",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...tokenStore.createHeader()
    },
    body: JSON.stringify({
      parent_key: 0,
      name,
      description,
      secure_str4,
      secure_yn,
      kms_yn,
      cre_user_key: store.myInfo?.user_key,
      cre_date: new Date().toISOString(),
      delete_yn: 0
    })
  });
  console.log(
    JSON.stringify({
      parent_key: 0,
      name,
      cre_user_key: store.myInfo?.user_key,
      cre_date: new Date().toISOString(),
      delete_yn: 0
    })
  );

  if (response.ok) {
    // 추가 성공 처리
    console.log("채널 추가 성공");
    return (await response.json()) as Channel[];
  } else {
    // 추가 실패 처리
    console.error("채널 추가 실패:", response.statusText);
    console.log(baseUrl.value);

    if (response.status === 401) {
      tokenStore.logOut();
    }
  }
}
// 채널 수정
export async function updateChannel(channel: Channel) {
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/channel`, {
    method: "PUT",
    mode: "cors",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...tokenStore.createHeader()
    },
    body: JSON.stringify(channel)
  });

  if (response.ok) {
    // 추가 성공 처리
    console.log("채널 업데이트 성공", channel);
    return (await response.json()) as Channel[];
  } else {
    // 추가 실패 처리
    console.error("채널 업데이트 실패:", response.statusText);
    console.log(baseUrl.value);

    if (response.status === 401) {
      tokenStore.logOut();
    }
    if (response.status !== 200) {
      console.error(
        `Error updating channel: ${response.status} - ${response.statusText}`
      );
      const errorDetail = await response.json();
      console.error("Error detail:", errorDetail);
    }
  }
}
export interface ReqDeleteChannel {
  channel_key: number;
}
// 채널 삭제
export async function deleteChannel(channel_key: number) {
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/channel`, {
    method: "DELETE",
    mode: "cors",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...tokenStore.createHeader()
    },
    body: JSON.stringify({
      channel_key
    } satisfies ReqDeleteChannel)
  });

  if (response.ok) {
    // 추가 성공 처리
    console.log("채널 삭제 성공");
    return (await response.json()) as Channel[];
  } else {
    // 추가 실패 처리
    console.error("채널 삭제 실패:", response.statusText);
    console.log(baseUrl.value);

    if (response.status === 401) {
      tokenStore.logOut();
    }
  }
}

export interface AuthChannel extends Channel {
  auth_type?: AuthUser["auth_type"] | "";
}

export interface ReqJoinToTheChannel {
  auth_user: Omit<AuthUser, "auth_user_key" | "grant_date">; // AuthUser 객체
}
export interface ReqChannelAnswerWithSecure extends ReqJoinToTheChannel {
  secure_str4: string;
}
//채널 구독
export async function joinChannel(param: ReqChannelAnswerWithSecure) {
  const tokenStore = useTokenStore();

  const response = await fetch(`${baseUrl.value}/channel/join`, {
    method: "POST",
    mode: "cors",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...(tokenStore.createHeader() as Record<string, string>)
    },
    body: JSON.stringify(param)
  });

  if (response.ok) {
    console.log("채널 구독 성공");
    return (await response.json()) as ResChannelList;
  } else {
    console.error("채널 구독 실패:", response.statusText);
    console.log(baseUrl.value);
  }
  return response;
  // if (response.ok) {
  //   console.log("채널 구독 성공");
  //   return (await response.json()) as ResChannelList;
  // } else {
  //   console.error("채널 구독 실패:", response.statusText);
  //   console.log(baseUrl.value);

  //   if (response.status === 401) {
  //     tokenStore.logOut();
  //   }
  // }
}

export interface OutChannel {
  user_key: number;
  channel_key: number;
}
//채널 구독 취소
export async function unjoinChannel(param: OutChannel) {
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/channel/unjoin`, {
    method: "POST",
    mode: "cors",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...tokenStore.createHeader()
    },
    body: JSON.stringify(param)
  });

  if (response.ok) {
    // 추가 성공 처리
    console.log("채널 구독취소 성공");
    return (await response.json()) as ResChannelList;
  } else {
    // 추가 실패 처리
    console.error("채널 구독취소 실패:", response.statusText);
    console.log(baseUrl.value);

    if (response.status === 401) {
      tokenStore.logOut();
    }
  }
}

// export interface BaseChannelParams
//   extends Omit<AuthUser, "auth_user_key" | "grant_date"> {
//   // secure_str4는 여기서 제거
// }

// export interface JoinChannelParams {
//   secure_str4: string; // 추가할 secure_str4 속성
// }
// export async function joinToTheChannel(
//   baseParams: BaseChannelParams, // 나머지 속성들
//   secureParams: JoinChannelParams // secure_str4 속성
// ) {
//   const tokenStore = useTokenStore();

//   // secure_str4를 baseParams에 추가
//   // const param = { ...baseParams, ...secureParams };
//   console.log("baseParams", baseParams);
//   console.log("secureParams", secureParams);

//   const response = await fetch(`${baseUrl.value}/channel/join`, {
//     method: "POST",
//     mode: "cors",
//     headers: {
//       accept: "application/json",
//       "Content-Type": "application/json",
//       ...tokenStore.createHeader()
//     },
//     // body: JSON.stringify(param)
//     body: JSON.stringify({
//       ...baseParams,
//       ...{ secureParams }
//     })
//   });
//   console.log("response", response);
//   if (response.ok) {
//     console.log("채널 구독 성공");
//     return (await response.json()) as ResChannelList;
//   } else {
//     console.error("채널 구독 실패:", response.statusText);
//     console.log(baseUrl.value);

//     if (response.status === 401) {
//       tokenStore.logOut();
//     }
//   }
// }

// 원본
// export async function joinToTheChannel(
//     param: Omit<AuthUser, "auth_user_key" | "grant_date">
//   ) {
//   const tokenStore = useTokenStore();
//   const response = await fetch(`${baseUrl.value}/channel/join`, {
//     method: "POST",
//     mode: "cors",
//     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//     // @ts-ignore
//     headers: {
//       accept: "application/json",
//       "Content-Type": "application/json",
//       ...tokenStore.createHeader()
//     },
//     body: JSON.stringify(param)
//   });

//   if (response.ok) {
//     // 추가 성공 처리
//     console.log("채널 구독 성공");
//     return (await response.json()) as ResChannelList;
//   } else {
//     // 추가 실패 처리
//     console.error("채널 구독 실패:", response.statusText);
//     console.log(baseUrl.value);

//     if (response.status === 401) {
//       tokenStore.logOut();
//     }
//   }
// }

export interface ReqChannelAnswer {
  msg_check_key: number;
  room_key: number;
  parent_key: number;
  user_key: number;
  question: string;
  answer: string;
  is_chunk: boolean;
}
//전문가 답변 완료
export async function createExpertAnswer(param: ReqChannelAnswer) {
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/channel/expert-answer`, {
    method: "POST",
    mode: "cors",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...tokenStore.createHeader()
    },
    body: JSON.stringify(param)
  });

  if (response.ok) {
    // 추가 성공 처리
    console.log("전문가 응답 성공");
  } else {
    // 추가 실패 처리
    console.error("전문가 응답 실패:", response.statusText);

    if (response.status === 401) {
      tokenStore.logOut();
    }
  }
}
//전문가 답변 거절
export async function deleteExpertAnswer(msg_check_key: number) {
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/channel/expert-answer`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...tokenStore.createHeader()
    },
    body: JSON.stringify({ msg_check_key })
  });

  if (response.ok) {
    // 추가 성공 처리
    console.log("전문가 응답 성공");
  } else {
    // 추가 실패 처리
    console.error("전문가 응답 실패:", response.statusText);
  }
}

// == Knowledge ================================================================
// == GET =======================
export interface ResKnow {
  id: string;
  know: string;
  metadata: {
    source: string;
    cre_date: string;
    delete_yn: number;
  };
}

//지식 정보 불러오기
export async function getKnowList(channel_key: number) {
  const response = await fetch(
    `${baseUrl.value}/knowledge2/?channel_key=${channel_key}`,
    {
      mode: "cors",
      headers: {
        accept: "application/json"
      }
    }
  );

  if (response.ok) {
    const knowledgeList: Know[] = await response.json();
    // 지식 목록을 처리하는 로직 추가
    console.log("지식 목록:", knowledgeList);
    return knowledgeList;
  } else {
    // 실패 시 처리
    console.error("지식 목록 불러오기 실패:", response.statusText);
    return [];
  }
}
export interface ResKnowCheck extends KnowCheck {
  know: KmsKnow;
  user: User;
}
// //지식 정보 불러오기
// export async function getKnowList(collection_id: string) {
//   const response = await fetch(
//     `${baseUrl.value}/knowledge2/?collection_id=${collection_id}`,
//     {
//       mode: "cors",
//       headers: {
//         accept: "application/json"
//       }
//     }
//   );

//   if (response.ok) {
//     const knowledgeList: Know[] = await response.json();
//     // 지식 목록을 처리하는 로직 추가
//     console.log("지식 목록:", knowledgeList);
//     return knowledgeList;
//   } else {
//     // 실패 시 처리
//     console.error("지식 목록 불러오기 실패:", response.statusText);
//     return [];
//   }
// }
// export interface ResKnowCheck extends KnowCheck {
//   user: User;
// }

//지식검토목록 불러오기
export async function getKnowCheckList(channel_key: number) {
  const response = await fetch(
    `${baseUrl.value}/knowledge2/check/?channel_key=${channel_key}`,
    {
      mode: "cors",
      headers: {
        accept: "application/json"
      }
    }
  );

  if (response.ok) {
    const knowledgeList: ResKnowCheck[] = await response.json();
    // 지식 목록을 처리하는 로직 추가
    console.log("지식 검토 목록:", knowledgeList);
    return knowledgeList;
  } else {
    // 실패 시 처리
    console.error("지식 검토 목록 불러오기 실패:", response.statusText);
    return [];
  }
}
//원본 지식 불러오기 -> 사용 예정
export async function getKnowOrgList(channel_key: number) {
  const response = await fetch(
    `${baseUrl.value}/knowledge2/org/?channel_key=${channel_key}`,
    {
      mode: "cors",
      headers: {
        accept: "application/json"
      }
    }
  );

  if (response.ok) {
    const knowledgeList: KnowOrg[] = await response.json();
    // 지식 목록을 처리하는 로직 추가
    console.log("지식 검토 목록:", knowledgeList);
    return knowledgeList;
  } else {
    // 실패 시 처리
    console.error("지식 검토 목록 불러오기 실패:", response.statusText);
    return [];
  }
}
export interface ReqAddKnowWithRefine {
  is_chunk: boolean;
  know: string;
  channel_key: number;
  keywordList: string[];
  linkList: string[];
}

// == POST/PUT/DELETE =======================
//최초 지식 입력
export async function createKnowOrg(body: ReqAddKnowWithRefine) {
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/knowledge2/org`, {
    method: "POST",
    mode: "cors",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...tokenStore.createHeader()
    },
    body: JSON.stringify(body)
  });

  if (response.ok) {
    console.log("지식이 저장되었습니다", response);
    return true;
  } else {
    if (response.status === 401) {
      tokenStore.logOut();
    }
    return false;
  }
}

//지식 수정하기
export async function updateKnow(id: string, know: string) {
  const response = await fetch(`${baseUrl.value}/knowledge2/`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      id,
      know
    })
  });

  if (response.ok) {
    // 추가 성공 처리
    console.log("지식 업데이트 성공!");
    return true;
  } else {
    // 추가 실패 처리
    console.error("지식 업데이트 실패:", response.statusText);
    return false;
  }
}
// 지식 삭제
export async function deleteKnow(id: string) {
  console.log("지식 삭제중");
  // `${baseUrl.value}/knowledge2/?collection_id=${collection_id}`,
  const response = await fetch(`${baseUrl.value}/knowledge2/?id=${id}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (response.ok) {
    // 추가 성공 처리
    console.log("지식 제거 성공!", response);
    return true;
  } else {
    // 추가 실패 처리
    console.error("지식 제거 실패:", response.statusText);
    return false;
  }
}

export interface ReqKnowOrg {
  knowList: string[];
  channel_key: number;
}

interface ReqAcceptKnow {
  know_check_key: number;
}
//검토지식 승인
export async function acceptKnowCheck(body: ReqAcceptKnow) {
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/knowledge2/check/accept`, {
    method: "POST",
    mode: "cors",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...tokenStore.createHeader()
    },
    body: JSON.stringify(body)
  });

  if (response.ok) {
    return true;
  } else {
    if (response.status === 401) {
      tokenStore.logOut();
    }
    return false;
  }
}

interface ReqRejectKnow {
  know_check_key: number;
}
//검토지식 반려
export async function rejectKnowCheck(body: ReqRejectKnow) {
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/knowledge2/check/reject`, {
    method: "POST",
    mode: "cors",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...tokenStore.createHeader()
    },
    body: JSON.stringify(body)
  });

  if (response.ok) {
    return true;
  } else {
    if (response.status === 401) {
      tokenStore.logOut();
    }
    return false;
  }
}

interface ReqRefineKnow {
  know: string;
}
interface ResRefineKnow {
  refineKnowList: string[];
}

//안쓰는 것 ??
export async function refineKnow(body: ReqRefineKnow) {
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/knowledge2/org`, {
    method: "POST",
    mode: "cors",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...tokenStore.createHeader()
    },
    body: JSON.stringify(body)
  });

  if (response.ok) {
    return (await response.json()) as ResRefineKnow;
  } else {
    if (response.status === 401) {
      tokenStore.logOut();
    }
    return {
      refineKnowList: []
    } satisfies ResRefineKnow;
  }
}

//안쓰는 것?
export async function addKnowOrgList(body: ReqKnowOrg) {
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/knowledge2/org`, {
    method: "POST",
    mode: "cors",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...tokenStore.createHeader()
    },
    body: JSON.stringify(body)
  });

  if (response.ok) {
    return true;
  } else {
    if (response.status === 401) {
      tokenStore.logOut();
    }
    return false;
  }
}

//안쓰는 것 ?
export async function addKnowledge(collection_id: string, knowledge: string) {
  console.log(collection_id);
  console.log(knowledge);
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/knowledge2/`, {
    method: "POST",
    mode: "cors",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: {
      "Content-Type": "application/json",
      ...tokenStore.createHeader()
    },
    body: JSON.stringify({
      collection_id,
      knowList: [knowledge]
    })
  });

  if (response.ok) {
    // 추가 성공 처리
    console.log("지식 추가 성공!");
    return true;
  } else {
    // 추가 실패 처리
    console.error("지식 추가 실패:", response.statusText);

    if (response.status === 401) {
      tokenStore.logOut();
    }
    return false;
  }
}

// == Msg ======================================================================
// 강서도서관 질문응답 예상 api 프로토타입
// requestAnytalkAnswer(systemToken:str, channelKey:int, askStr: str)
// {
//   answerResult{
//     resultCode: OK, ERRS, ERRP, ERRK
//     resultMsg: 정상,서비스오류, Param없음, 지식없음
//     answerStr:
//   }
// }

// == GET =======================
export interface ResultAnytalkAnswer {
  resultCode: string;
  resultMsg: string;
  answerStr: string;
}

export interface RequestAnytalkAnswer {
  systemToken: string;
  // channelKey: number;
  askStr: string;
}

//채널대화목록 불러오기
export async function getMsgList(room_key: number) {
  const response = await fetch(
    `${baseUrl.value}/message/?room_key=${room_key}`,
    {
      mode: "cors",
      headers: {
        accept: "application/json"
      }
    }
  );

  if (response.ok) {
    const msgList: Msg[] = await response.json();
    // console.log("메세지 목록:", msgList);
    return msgList;
  } else {
    console.error("메세지 불러오기 실패:", response.statusText);
    return [];
  }
}

// 질문목록 불러오기
export async function getMsgCheckList(channelKey: number) {
  const tokenStore = useTokenStore();
  const response = await fetch(
    `${baseUrl.value}/message/check/?channel_key=${channelKey}`,
    {
      mode: "cors",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        ...tokenStore.createHeader()
      }
    }
  );

  if (response.ok) {
    // 추가 성공 처리
    console.log("채널 질문 목록 로드 성공");
    return (await response.json()) as MsgCheck[];
  } else {
    // 추가 실패 처리
    console.error("채널 질문 목록 로드 실패:", response.statusText);
  }
}

// == POST/PUT/DELETE =======================
export interface ReqResponseStream {
  multi_query: boolean;
  // collection_id: number;
  question: string;
  // Vector store
  // know_score_limit?: number;
  channel_key: number;

  // Know scope
  know_scope: "independent" | "socialOpen" | "relatedChannel";
  // With Message History
  user_key?: number;
  room_key?: number;
  file_key_listxxx?: number[];
  first_yn?: number;
}
// //채팅 스트리밍으로 받기
export async function createMsg(requestMsg: ReqResponseStream) {
  const response = await fetch(`${baseUrl.value}/message`, {
    method: "POST",
    mode: "cors",
    headers: {
      accept: "text/event-stream",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(requestMsg)
  });

  const reader = response.body
    ?.pipeThrough(new TextDecoderStream())
    .getReader();

  return reader;
}
//채팅 스트리밍으로 받기
export async function requestKmsAnswerStream(requestMsg: ReqResponseStream) {
  const response = await fetch(`${baseUrl.value}/kms/requestKmsAnswerStream`, {
    method: "POST",
    mode: "cors",
    headers: {
      accept: "text/event-stream",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(requestMsg)
  });

  const reader = response.body
    ?.pipeThrough(new TextDecoderStream())
    .getReader();

  return reader;
}

export interface ReqDeleteMsg {
  msg_key: number;
}
//메시지 삭제
export async function deleteMsg(msg_key: number) {
  const response = await fetch(`${baseUrl.value}/message/?msg_key=${msg_key}`, {
    // const response = await fetch(`${baseUrl.value}/message/?msg_key=${msg_key}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      accept: "application/json"
    },
    body: JSON.stringify({
      msg_key
    } satisfies ReqDeleteMsg)
  });

  if (response.ok) {
    // const msgList: Msg[] = await response.json(); // Uncommented this line
    // console.log("msgList",msgList)
    // return msgList; // Return the updated message list
    console.log("deleteMsg OK");
  } else {
    console.error("메시지 삭제 실패:", response.statusText);
  }

  return []; // Consider returning an empty array or handling the error case more gracefully
}

//TTS 호출
export async function getMsgTts(text: string) {
  const response = await fetch(`${baseUrl.value}/message/tts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ text }),
    mode: "cors"
  });

  if (response.ok) {
    console.log("text", text);
    // 추가 성공 처리
    const audioBlob = await response.blob();
    const audioArrayBuffer = await audioBlob.arrayBuffer();

    return audioArrayBuffer;
  } else {
    // 추가 실패 처리
    return;
  }
}

export const LANGUAGE = {
  ENGLISH: {
    name: "영어 (English)",
    code: "en-US"
  },
  FRENCH: {
    name: "프랑스어 (Français)",
    code: "fr-FR"
  },
  GERMAN: {
    name: "독일어 (Deutsch)",
    code: "de-DE"
  },
  KOREAN: {
    name: "한국어 (Korean)",
    code: "ko-KR"
  },
  JAPANESE: {
    name: "일본어 (日本語)",
    code: "ja-JP"
  },
  VIETNAMESE: {
    name: "베트남어 (Tiếng Việt)",
    code: "vi-VN"
  },
  THAI: {
    name: "태국어 (ภาษาไทย)",
    code: "th-TH"
  },
  CHINESE: {
    name: "중국어 (中文)",
    code: "cmn-Hans-CN"
  }
  // CHINESE: {
  //   name: "중국어간체 (Chinese)",
  //   code: "cmn-Hans-CN"
  // },
  // CHINESE_H: {
  //   name: "중국어간체홍콩 (Chinese-Hong Kong)",
  //   code: "cmn-Hans-HK"
  // },
  // CHINESE_D: {
  //   name: "중국어번체대만 (Chinese-Taiwan)",
  //   code: "cmn-Hant-TW"
  // }
};

// export interface ResTTS {
//   code: string;
//   text: string;
// }
//안쓰는 것
export async function convertSpeechToText(lang: string, audioChunks: Blob[]) {
  const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
  if (audioBlob.size === 0) return "";

  const formData = new FormData();
  formData.append("lang", lang);
  formData.append("audio", audioBlob);

  const response = await fetch(`${baseUrl.value}/message/stt`, {
    method: "POST",
    headers: {
      accept: "application/json"
      // boundary 설정을 위해 수동으로 하지 않음!!!!
      // "Content-Type": "multipart/form-data"
    },
    body: formData
  });

  if (response.ok) {
    // 추가 성공 처리
    const result = (await response.json()) as ResTTS;
    return result.text;
  } else {
    // 추가 실패 처리
    return "";
  }
}

// STT 자동언어 감지 테스트
// export interface ResTTS2 {
//   lang: string;
//   updateKnow: string;
// }
//안쓰는 것
// export async function convertSpeechToText2(audioChunks: Blob[]) {
//   const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
//   if (audioBlob.size === 0) return "";

//   const formData = new FormData();
//   // formData.append("lang", lang);
//   formData.append("audio", audioBlob);

//   const response = await fetch(`${baseUrl.value}/message/stt2`, {
//     method: "POST",
//     headers: {
//       accept: "application/json"
//       // boundary 설정을 위해 수동으로 하지 않음!!!!
//       // "Content-Type": "multipart/form-data"
//     },
//     body: formData
//   });

//   if (response.ok) {
//     // 추가 성공 처리
//     const result = (await response.json()) as ResTTS2;

//     return result.updateKnow;
//   } else {
//     // 추가 실패 처리
//     return "";
//   }
// }

// == Room =====================================================================
// == GET =======================
//룸 정보 불러오기
export interface RoomInfo extends Room, Omit<RoomUser, "key" | "room_key"> {}
export async function getRoomList(channel_key: number, user_key: number) {
  const tokenStore = useTokenStore();
  const response = await fetch(
    // `${baseUrl.value}/room?channel_key=${channel_key}`,
    `${baseUrl.value}/room/?channel_key=${channel_key}&user_key=${user_key}`,
    {
      mode: "cors",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headers: {
        accept: "application/json",
        ...tokenStore.createHeader()
      }
    }
  );
  if (response.ok) {
    // debugger;
    // 추가 성공 처리
    console.log("룸 목록 로드 성공");
    return (await response.json()) as RoomInfo[];
  } else {
    // 추가 실패 처리
    console.error("룸 목록 로드 실패:", response.statusText);

    if (response.status === 401) {
      tokenStore.logOut();
    }
    return [];
  }
}

// == User =====================================================================
// == GET =======================
//내 정보 불러오기
export async function getMyInfo() {
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/user/myinfo`, {
    mode: "cors",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: {
      accept: "application/json",
      ...tokenStore.createHeader()
    }
  });

  if (response.ok) {
    // 추가 성공 처리
    console.log("채널 목록 로드 성공");
    return (await response.json()) as User;
  } else {
    // 추가 실패 처리
    console.error("채널 목록 로드 실패:", response.statusText);
    console.log(baseUrl.value);

    if (response.status === 401) {
      tokenStore.logOut();
    }
  }
}

export interface Credentials {
  username: string;
  password: string;
}
// //기본 로그인
export async function loginIdPw(credentials: Credentials) {
  console.log(`${baseUrl.value}/user/login-idpw-nsu`);
  const response = await fetch(
    // `${baseUrl.value}/user/login-idpw-nsu/?username=${credentials.username}`,
    `${baseUrl.value}/user/login-idpw/?username=${credentials.username}&password=${credentials.password}`,
    {
      method: "POST",
      mode: "cors"
      // headers: {
      //   "Content-Type": "application/x-www-form-urlencoded"
      // },
      // body: new URLSearchParams({
      //   username: credentials.username,
      //   password: credentials.password
      // })
    }
  );

  if (response.ok) {
    // 추가 성공 처리
    console.log("로그인 성공!");
    // return (await response.json()) as Token;
    const data = await response.json();
    return { access_token: data.access_token, user: data.user } as {
      access_token: string;
      user: User;
    };
  } else {
    // 추가 실패 처리
    console.error("로그인 실패:", response.statusText);
  }
}
// //기본 로그인
// export async function loginIdPw(credentials: Credentials) {
//   console.log(`${baseUrl.value}/user/login-idpw`);
//   const response = await fetch(
//     `${baseUrl.value}/user/login-idpw/?username=${credentials.username}&password=${credentials.password}`,
//     {
//       method: "POST",
//       mode: "cors"
//       // headers: {
//       //   "Content-Type": "application/x-www-form-urlencoded"
//       // },
//       // body: new URLSearchParams({
//       //   username: credentials.username,
//       //   password: credentials.password
//       // })
//     }
//   );

//   if (response.ok) {
//     // 추가 성공 처리
//     console.log("로그인 성공!");
//     // return (await response.json()) as Token;
//     const data = await response.json();
//     return { access_token: data.access_token, user: data.user } as {
//       access_token: string;
//       user: User;
//     };
//   } else {
//     // 추가 실패 처리
//     console.error("로그인 실패:", response.statusText);
//   }
// }

// export async function loginIdPw(credentials: Credentials) {
//   console.log(`${baseUrl.value}/user/login-idpw-nsu`);
//   const response = await fetch(
//     `${baseUrl.value}/user/login-idpw-nsu/?username=${credentials.username}`,
//     {
//       method: "POST",
//       mode: "cors"
//       // headers: {
//       //   "Content-Type": "application/x-www-form-urlencoded"
//       // },
//       // body: new URLSearchParams({
//       //   username: credentials.username,
//       //   password: credentials.password
//       // })
//     }
//   );

//   if (response.ok) {
//     // 추가 성공 처리
//     console.log("로그인 성공!");
//     // return (await response.json()) as Token;
//     const data = await response.json();
//     return { access_token: data.access_token, user: data.user } as {
//       access_token: string;
//       user: User;
//     };
//   } else {
//     // 추가 실패 처리
//     console.error("로그인 실패:", response.statusText);
//   }
// }

export interface ReqSSOUser {
  uid: string | null;
  email: string | null;
  nick: string | null;
  photo_url: string | null;
  provider_id: string;
  name: string | null;
}
// //SSO 로그인
// export async function loginSso(body: ReqSSOUser) {
//   const response = await fetch(`${baseUrl.value}/user/login-sso`, {
//     method: "POST",
//     mode: "cors",
//     headers: {
//       accept: "application/json",
//       "Content-Type": "application/json"
//     },
//     body: JSON.stringify(body)
//   });

//   if (response.ok) {
//     // 추가 성공 처리
//     console.log("로그인 성공!");
//     // return (await response.json()) as Token;
//     const data = await response.json();
//     return { access_token: data.access_token, user: data.user } as {
//       access_token: string;
//       user: User;
//     };
//   } else {
//     // 추가 실패 처리
//     console.error("로그인 실패:", response.statusText);
//   }
// }
export async function loginSso(body: ReqSSOUser) {
  const response = await fetch(`${baseUrl.value}/user/login-sso`, {
    method: "POST",
    mode: "cors",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  });

  if (response.ok) {
    console.log("로그인 성공!");
    const data = await response.json();

    return {
      access_token: data.access_token, // ✅ access_token 반환
      user: data.user, // ✅ user 반환
      e_mail: data.e_mail
    } as {
      access_token: string;
      user: User;
      e_mail: string;
    };
  } else {
    console.error("로그인 실패:", response.statusText);
    return null;
  }
}

// == WebSite Crawling ==================================================================

// 도메인 리스트 불러오기
export async function getWebDomainList() {
  // 인코딩된 URL을 사용하여 API 요청을 보냅니다.
  const response = await fetch(`${baseUrl.value}/web/domain`, {
    mode: "cors",
    headers: {
      accept: "application/json"
    }
  });

  // 응답이 성공적인 경우 JSON 데이터를 반환합니다.
  if (response.ok) {
    const webDomainList: ResDomain[] = await response.json();
    console.log("웹페이지 데이터:", webDomainList);
    return webDomainList;
  } else {
    // 실패한 경우 에러를 로그에 기록합니다.
    console.error("웹페이지 데이터 불러오기 실패:", response.statusText);
    return [];
  }
}

export interface ReqAddWebDomain {
  title: string;
  description: string;
  url: string;
  interval: string;
  count: number;
  depth: number;
  additional_domains: [];
}

// 도메인 추가하기
export async function createWebDomain(newDomain: ReqAddWebDomain) {
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/web/domain`, {
    method: "POST",
    mode: "cors",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...(tokenStore.createHeader() as Record<string, string>)
    },
    body: JSON.stringify(newDomain)
  });
  if (response.ok) {
    // 추가 성공 처리
    console.log("지식 추가 성공!");
    return true;
  } else {
    // 추가 실패 처리
    console.error("지식 추가 실패:", response.statusText);

    if (response.status === 401) {
      console.error("웹페이지 데이터 불러오기 실패:", response.statusText);
    }
    return false;
  }
}

//도메인 페이지 리스트 불러오기
export async function getWebPageList(domainKey: number) {
  const response = await fetch(
    `${baseUrl.value}/web/page?domain_key=${domainKey}`,
    {
      mode: "cors",
      headers: {
        accept: "application/json"
      }
    }
  );
  // 응답이 성공적인 경우 JSON 데이터를 반환합니다.
  if (response.ok) {
    const webPageList: ResWebPage[] = await response.json();
    console.log("웹페이지 데이터:", webPageList);
    return webPageList;
  } else {
    // 실패한 경우 에러를 로그에 기록합니다.
    console.error("웹페이지 데이터 불러오기 실패:", response.statusText);
    return [];
  }
}

// 웹 페이지 설정영역 가져오기
export async function getWebPageInfo(pageKey: number) {
  const response = await fetch(
    `${baseUrl.value}/web/page-info?page_key=${pageKey}`,
    {
      mode: "cors",
      headers: {
        accept: "application/json"
      }
    }
  );
  // 응답이 성공적인 경우 JSON 데이터를 반환합니다.
  if (response.ok) {
    const webPageList: ResWebPage = await response.json();
    console.log("웹페이지 데이터:", webPageList);
    return webPageList;
  } else {
    // 실패한 경우 에러를 로그에 기록합니다.
    console.error("웹페이지 데이터 불러오기 실패:", response.statusText);
  }
}

//도메인 페이지 생성하기
export async function createWebPage(fcmToken: string, domainKey: number) {
  const response = await fetch(`${baseUrl.value}/web/page`, {
    method: "POST",
    mode: "cors",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      fcm_token: fcmToken,
      domain_key: domainKey
    })
  });

  if (response.ok) {
    // 생성 성공 처리
    console.log("페이지 생성 성공!");
    return true;
  } else {
    // 생성 실패 처리
    console.error("페이지 생성 실패:", response.statusText);

    if (response.status === 401) {
      console.error("페이지 데이터 불러오기 실패:", response.statusText);
    }
    return false;
  }
}

//도메인 페이지 생성하기 - 김정희 크롤링 모델 테스트용
export async function createWebPageTest(fcmToken: string, url: string) {
  // 정희 생성 api주소
  const response = await fetch(`${baseUrl.value}/web/webpage`, {
    method: "POST",
    mode: "cors",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      fcm_token: fcmToken,
      url: url
    })
  });

  if (response.ok) {
    // 생성 성공 처리
    console.log("페이지 생성 성공!");
    return true;
  } else {
    // 생성 실패 처리
    console.error("페이지 생성 실패:", response.statusText);

    if (response.status === 401) {
      console.error("페이지 데이터 불러오기 실패:", response.statusText);
    }
    return false;
  }
}

export interface ResWebPageContent {
  url: string;
  web_page_content_key: number;
  cr_title_el: string;
  cr_body_el: string;
  interval: string;
  web_page_key: number;
  delete_yn: number;
  title: string;
  cr_title_data: string;
  cr_body_data: string;
  depth: number;
  cre_date: string;
  upd_date: string;
}

//도메인 콘텐츠 불러오기
// export async function getWebPageContentList(web_page_key: number) {
//   try {
//     const response = await fetch(
//       `${baseUrl.value}/web/page-content?web_page_key=${web_page_key}`,
//       {
//         mode: "cors",
//         headers: {
//           accept: "application/json"
//         }
//       }
//     );

//     if (response.ok) {
//       const webPageContentList: ResWebPageContent[] = await response.json();
//       console.log("웹 페이지 콘텐츠 불러오기:", webPageContentList);
//       return webPageContentList;
//     } else {
//       console.error(
//         "웹 페이지 콘텐츠 불러오기 실패:",
//         response.status,
//         response.statusText
//       );
//       return [];
//     }
//   } catch (error) {
//     console.error("네트워크 오류 발생:", error);
//     return [];
//   }
// }

export async function getWebPageContentList(
  web_page_key: number,
  domain_key?: number
) {
  try {
    const queryParams = new URLSearchParams({
      web_page_key: web_page_key.toString(),
      ...(domain_key && { domain_key: domain_key.toString() }) // domain_key가 존재하면 추가
    });

    const response = await fetch(
      `${baseUrl.value}/web/page-content?${queryParams.toString()}`,
      {
        mode: "cors",
        headers: {
          accept: "application/json"
        }
      }
    );

    if (response.ok) {
      const webPageContentList: ResWebPageContent[] = await response.json();
      console.log("웹 페이지 콘텐츠 불러오기:", webPageContentList);
      return webPageContentList;
    } else {
      console.error(
        "웹 페이지 콘텐츠 불러오기 실패:",
        response.status,
        response.statusText
      );
      return [];
    }
  } catch (error) {
    console.error("네트워크 오류 발생:", error);
    return [];
  }
}
//도메인 콘텐츠 추가하기
export async function createPageContent(domain_key: number) {
  const response = await fetch(`${baseUrl.value}/web/contents`, {
    method: "POST",
    mode: "cors",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      domain_key: domain_key
    })
  });

  if (response.ok) {
    // 생성 성공 처리
    console.log("페이지 생성 성공!");
    return true;
  } else {
    // 생성 실패 처리
    console.error("페이지 생성 실패:", response.statusText);

    if (response.status === 401) {
      console.error("페이지 데이터 불러오기 실패:", response.statusText);
    }
    return false;
  }
}

//=========================== 웹소켓

export async function getWebSocket(user_key: string) {
  const response = await fetch(`${baseUrl.value}/ws/${user_key}`, {
    mode: "cors",
    headers: {
      accept: "application/json"
    }
  });

  // get이 성공적인 경우 JSON 데이터를 반환합니다.
  if (response.ok) {
    const webSocket = await response.json();
    console.log("웹 페이지 콘텐츠 불러오기:", webSocket);
    return webSocket;
  } else {
    // 실패한 경우 에러를 로그에 기록합니다.
    console.error("웹 페이지 콘텐츠 불러오기 실패:", response.statusText);
    return [];
  }
}

//============================= KMS

export interface FileData {
  kms_folder_key: number;
  kms_folder_group_key: number;
  parent_folder_key: number;
  folder_name: string;
  delete_yn: number;
}

export interface UpdateKmsKnowRequest {
  kms_know_key: number;
  // 독 업데이트
  // kms_doc_key?: number;
  kms_folder_key?: number;
  channel_key?: number;
  lc_know_id?: string;
  confirm_yn?: number;
  title?: string;
  summary?: string;
  body?: string;
  must_text?: string;
  keyword_list?: string[];
  quest_list?: string[];
  img_filekey_list?: number[];
  attach_filekey_list?: number[];
  linkkey_list?: number[];
  delete_yn?: number;
  valid_yn?: number;
  expire_date?: number;
}

// 폴더명
// 폴더 그룹 목록 가져오기
export async function getKmsFolderGroupList(channel_key: number) {
  // const response = await fetch(
  //   `${baseUrl.value}/kms/folder/group?channel_key=${channel_key}&folder_group_type=K`,
  //   {
  // const folder_group_system_code = null;
  const response = await fetch(
    `${baseUrl.value}/kms/folder/group?channel_key=${channel_key}&folder_group_type=K`,
    {
      mode: "cors",
      headers: {
        accept: "application/json"
      }
    }
  );

  if (response.ok) {
    const kmsFolderGroupList = await response.json();
    console.log("kms 폴더 그룹 데이터 불러오기:", kmsFolderGroupList);
    return kmsFolderGroupList;
  } else {
    const errorDetails = await response.json();
    console.error(
      "kms 폴더 그룹 데이터 불러오기 실패:",
      response.statusText,
      errorDetails
    );
    return [];
  }
}

export async function getKmsRepoImgFolderGroupList(channel_key: number) {
  const response = await fetch(
    `${baseUrl.value}/kms/folder/group?channel_key=${channel_key}&folder_group_type=F`,
    {
      mode: "cors",
      headers: {
        accept: "application/json"
      }
    }
  );

  if (response.ok) {
    const kmsFolderGroupList = await response.json();
    console.log(
      "kms 레포 이미지 폴더 그룹 데이터 불러오기:",
      kmsFolderGroupList
    );
    return kmsFolderGroupList;
  } else {
    const errorDetails = await response.json();
    console.error(
      "kms 레포 이미지 폴더 그룹 데이터 불러오기 실패:",
      response.statusText,
      errorDetails
    );
    return [];
  }
}

export async function getKmsRepoLinkFolderGroupList(channel_key: number) {
  const response = await fetch(
    `${baseUrl.value}/kms/folder/group?channel_key=${channel_key}&folder_group_type=L`,
    {
      mode: "cors",
      headers: {
        accept: "application/json"
      }
    }
  );

  if (response.ok) {
    const kmsFolderGroupList = await response.json();
    console.log("kms 레포 링크 폴더 그룹 데이터 불러오기:", kmsFolderGroupList);
    return kmsFolderGroupList;
  } else {
    const errorDetails = await response.json();
    console.error(
      "kms 레포 링크 폴더 그룹 데이터 불러오기 실패:",
      response.statusText,
      errorDetails
    );
    return [];
  }
}

// export async function getKmsFolderGroupList(channel_key: number) {
//   const response = await fetch(
//     `https://anytalk.kr:8082/kms/folder/group?channel_key=${channel_key}`,
//     {
//       mode: "cors",
//       headers: {
//         accept: "application/json"
//       }
//     }
//   );

//   // get이 성공적인 경우 JSON 데이터를 반환합니다.
//   if (response.ok) {
//     const kmsFolderGroupList = await response.json();
//     console.log("kms 폴더 그룹 데이터 불러오기:", kmsFolderGroupList);
//     return kmsFolderGroupList;
//   } else {
//     // 실패한 경우 에러를 로그에 기록합니다.
//     console.error("kms 폴더 그룹 데이터 불러오기 실패:", response.statusText);
//     return [];
//   }
// }

export async function getKmsFolderList(folder_group_key: number) {
  // const folder_system_code = "None";
  // const folder_system_code = none;
  const response = await fetch(
    `${baseUrl.value}/kms/folder?folder_group_key=${folder_group_key}`,
    // `${baseUrl.value}/kms/folder?folder_group_key=${folder_group_key}&folder_system_code=""`,
    {
      mode: "cors",
      headers: {
        accept: "application/json"
      }
    }
  );

  // get이 성공적인 경우 JSON 데이터를 반환합니다.
  if (response.ok) {
    const kmsFolderList = await response.json();
    console.log("kms 폴더 데이터 불러오기:", kmsFolderList);
    return kmsFolderList;
  } else {
    // 실패한 경우 에러를 로그에 기록합니다.
    console.error("kms 폴더 데이터 불러오기 실패:", response.statusText);
    return [];
  }
}

// 독 업데이트
// export async function getKmsDocList(folder_key: number) {
//   const response = await fetch(
//     `${baseUrl.value}/kms/doc?in_folder_key=${folder_key}`,
//     {
//       mode: "cors",
//       headers: {
//         accept: "application/json"
//       }
//     }
//   );

//   // get이 성공적인 경우 JSON 데이터를 반환합니다.
//   if (response.ok) {
//     const kmsdocList = await response.json();
//     console.log("kms 파일 데이터 불러오기:", kmsdocList);
//     return kmsdocList;
//   } else {
//     // 실패한 경우 에러를 로그에 기록합니다.
//     console.error("kms 파일 데이터 불러오기 실패:", response.statusText);
//     return [];
//   }
// }

export interface ReqKmsChannelKnow {
  channel_key_list?: number[];
  channel_key: number;
  file_key?: number;
  know_key_list?: number[];
  know_key_list_str?: number[];
  confirm_yn: number;
  search_text?: string;
  refine_yn?: number;
  sub_info_yn?: number;
  limit?: number;
}

// 채널 기준 모든 지식 목록 불러오기 (POST 요청)
// export async function getKmsChannelKnowList(params: ReqKmsChannelKnow) {
//   try {
//     const response = await fetch(`${baseUrl.value}/kms/know`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         accept: "application/json"
//       },
//       body: JSON.stringify(params)
//     });

//     if (response.ok) {
//       const kmsKnowList = await response.json();
//       console.log("kms 지식 데이터 불러오기 성공:", kmsKnowList);
//       return kmsKnowList;
//     } else {
//       console.error("kms 지식 데이터 불러오기 실패:", response.statusText);
//       return [];
//     }
//   } catch (error) {
//     console.error("API 요청 중 오류 발생:", error);
//     return [];
//   }
// } // ✅ 불필요한 `}` 제거됨

// 지식 검토 getKmsKnowList2
export async function getKmsKnowList2(params: ReqKmsChannelKnow) {
  try {
    // ✅ `params` 객체를 쿼리 스트링으로 변환
    const queryParams = new URLSearchParams();
    if (params.channel_key_list)
      queryParams.append("channel_key_list", params.channel_key_list.join(","));
    if (params.channel_key)
      queryParams.append("channel_key", String(params.channel_key));
    // if (params.file_key_list)
    //   queryParams.append("file_key_list", params.file_key_list.join(","));
    if (params.file_key)
      queryParams.append("file_key", String(params.file_key));
    if (params.know_key_list)
      queryParams.append("know_key_list", params.know_key_list.join(","));
    if (params.know_key_list_str)
      queryParams.append(
        "know_key_list_str",
        params.know_key_list_str.join(",")
      );
    if (params.confirm_yn !== undefined)
      queryParams.append("confirm_yn", String(params.confirm_yn));
    if (params.search_text)
      queryParams.append("search_text", params.search_text);
    if (params.refine_yn !== undefined)
      queryParams.append("refine_yn", String(params.refine_yn));
    if (params.sub_info_yn)
      queryParams.append("sub_info_yn", String(params.sub_info_yn));
    if (params.limit) queryParams.append("limit", String(params.limit));

    const url = `${baseUrl.value}/kms/know2?${queryParams.toString()}`;

    console.log("📡 GET 요청 URL:", url);

    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "application/json"
      }
    });

    if (response.ok) {
      const kmsKnowList = await response.json();
      console.log("✅ kms 지식 데이터 불러오기 성공:", kmsKnowList);
      return kmsKnowList;
    } else {
      console.error("❌ kms 지식 데이터 불러오기 실패:", response.statusText);
      return [];
    }
  } catch (error) {
    console.error("❌ API 요청 중 오류 발생:", error);
    return [];
  }
}

export async function searchKmsKnowElastic(
  channelKey: number,
  questStr: string,
  elastic_yn: number
) {
  try {
    // URL 파라미터 구성
    const params = new URLSearchParams();
    params.append("channel_key", String(channelKey));
    params.append("quest_str", questStr);
    params.append("elastic_yn", String(elastic_yn));

    const url = `${baseUrl.value}/kms/searchKmsKnowElastic?${params.toString()}`;

    console.log("📡 Elastic 검색 GET 요청 URL:", url);

    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "application/json"
      }
    });

    if (response.ok) {
      const elasticResults = await response.json();
      console.log("✅ Elastic 검색 성공:", elasticResults);
      return elasticResults;
    } else {
      console.error("❌ Elastic 검색 실패:", response.statusText);
      return [];
    }
  } catch (error) {
    console.error("❌ Elastic API 요청 중 오류 발생:", error);
    return [];
  }
}

// export async function getKmsKnowList2(params: ReqKmsChannelKnow) {
//   try {
//     // ✅ `params` 객체를 쿼리 스트링으로 변환
//     const queryParams = new URLSearchParams();

//     if (params.channel_key_list)
//       queryParams.append("channel_key_list", params.channel_key_list.join(","));
//     if (params.file_key_list)
//       queryParams.append("file_key_list", params.file_key_list.join(","));
//     if (params.know_key_list)
//       queryParams.append("know_key_list", params.know_key_list.join(","));
//     if (params.confirm_yn !== undefined)
//       queryParams.append("confirm_yn", String(params.confirm_yn));
//     if (params.search_text)
//       queryParams.append("search_text", params.search_text);
//     if (params.refine_yn !== undefined)
//       queryParams.append("refine_yn", String(params.refine_yn));

//     const url = `${baseUrl.value}/kms/know2?${queryParams.toString()}`;

//     console.log("📡 GET 요청 URL:", url);

//     const response = await fetch(url, {
//       method: "GET",
//       headers: {
//         accept: "application/json"
//       }
//     });

//     if (response.ok) {
//       const kmsKnowList = await response.json();
//       console.log("✅ kms 지식 데이터 불러오기 성공:", kmsKnowList);
//       return kmsKnowList;
//     } else {
//       console.error("❌ kms 지식 데이터 불러오기 실패:", response.statusText);
//       return [];
//     }
//   } catch (error) {
//     console.error("❌ API 요청 중 오류 발생:", error);
//     return [];
//   }
// }

// kms 파일 지식 불러오기
// 독 업데이트
// export async function getKmsKnowList(doc_key: number) {
//   const response = await fetch(`${baseUrl.value}/kms/know?doc_key=${doc_key}`, {
//     mode: "cors",
//     headers: {
//       accept: "application/json"
//     }
//   });

//   // get이 성공적인 경우 JSON 데이터를 반환합니다.
//   if (response.ok) {
//     const kmsKnowList = await response.json();
//     console.log("kms 지식 데이터 불러오기:", kmsKnowList);
//     return kmsKnowList;
//   } else {
//     // 실패한 경우 에러를 로그에 기록합니다.
//     console.error("kms 지식 데이터 불러오기 실패:", response.statusText);
//     return [];
//   }
// }
export async function getKmsKnowList(folder_key: number) {
  const response = await fetch(
    `${baseUrl.value}/kms/know?folder_key=${folder_key}`,
    {
      mode: "cors",
      headers: {
        accept: "application/json"
      }
    }
  );

  // get이 성공적인 경우 JSON 데이터를 반환합니다.
  if (response.ok) {
    const kmsKnowList = await response.json();
    console.log("kms 지식 데이터 불러오기:", kmsKnowList);
    return kmsKnowList;
  } else {
    // 실패한 경우 에러를 로그에 기록합니다.
    console.error("kms 지식 데이터 불러오기 실패:", response.statusText);
    return [];
  }
}

// export async function getKmsKnowConfirmYList(doc_key: number) {
//   const confirmYn = 1;
//   const response = await fetch(
//     `${baseUrl.value}/kms/know?doc_key=${doc_key}&confirm_yn=${confirmYn}`,
//     {
//       mode: "cors",
//       headers: {
//         accept: "application/json"
//       }
//     }
//   );

//   // get이 성공적인 경우 JSON 데이터를 반환합니다.
//   if (response.ok) {
//     const kmsKnowList = await response.json();
//     console.log("kms 지식 데이터 불러오기:", kmsKnowList);
//     return kmsKnowList;
//   } else {
//     // 실패한 경우 에러를 로그에 기록합니다.
//     console.error("kms 지식 데이터 불러오기 실패:", response.statusText);
//     return [];
//   }
// }

// export async function getKmsKnowConfirmNList(doc_key: number) {
//   const confirmYn = 0;
//   const response = await fetch(
//     `${baseUrl.value}/kms/know?doc_key=${doc_key}&confirm_yn=${confirmYn}`,
//     {
//       mode: "cors",
//       headers: {
//         accept: "application/json"
//       }
//     }
//   );

//   // get이 성공적인 경우 JSON 데이터를 반환합니다.
//   if (response.ok) {
//     const kmsKnowList = await response.json();
//     console.log("kms 지식 데이터 불러오기:", kmsKnowList);
//     return kmsKnowList;
//   } else {
//     // 실패한 경우 에러를 로그에 기록합니다.
//     console.error("kms 지식 데이터 불러오기 실패:", response.statusText);
//     return [];
//   }
// }

// 독 업데이트
// export async function getKmsKnowConfirmList(
//   channel_key: number,
//   doc_key: number,
//   confirmYn: number
// ) {
//   const response = await fetch(
//     `${baseUrl.value}/kms/know2?channel_key=${channel_key}&doc_key=${doc_key}&confirm_yn=${confirmYn}&limit=5000`,
//     {
//       method: "GET",
//       mode: "cors",
//       headers: {
//         accept: "application/json"
//       }
//     }
//   );

//   // get이 성공적인 경우 JSON 데이터를 반환합니다.
//   if (response.ok) {
//     const kmsKnowList = await response.json();
//     console.log("kms 지식 데이터 불러오기:", kmsKnowList);
//     return kmsKnowList;
//   } else {
//     // 실패한 경우 에러를 로그에 기록합니다.
//     console.error("kms 지식 데이터 불러오기 실패:", response.statusText);
//     return [];
//   }
// }
export async function getKmsKnowConfirmList(
  channel_key: number,
  folder_key: number,
  confirmYn: number
) {
  const response = await fetch(
    // `${baseUrl.value}/kms/know2?channel_key=${channel_key}&folder_key=${folder_key}&confirm_yn=${confirmYn}&limit=5000`,
    `${baseUrl.value}/kms/know2?channel_key=${channel_key}&folder_key=${folder_key}&confirm_yn=${confirmYn}&limit=50000`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        accept: "application/json"
      }
    }
  );

  // get이 성공적인 경우 JSON 데이터를 반환합니다.
  if (response.ok) {
    const kmsKnowList = await response.json();
    console.log("kms 지식 데이터 불러오기:", kmsKnowList);
    return kmsKnowList;
  } else {
    // 실패한 경우 에러를 로그에 기록합니다.
    console.error("kms 지식 데이터 불러오기 실패:", response.statusText);
    return [];
  }
}

// "kms_folder_key": 0,
// "kms_folder_group_key": 0,
// "parent_folder_key": 0,
// "folder_name": "string",
// "delete_yn": 0

export async function createKmsFolder(folder_data: folder_data) {
  try {
    // const response = await fetch("https://api.anytalk.kr/8082/kms/folder", {
    const response = await fetch(`${baseUrl.value}/kms/folder`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json"
      },
      body: JSON.stringify(folder_data)
    });

    if (response.ok) {
      const result = await response.json();
      console.log("폴더 추가 성공:", response);
      return result;
    } else {
      console.error("폴더 추가 실패:", response.statusText);
      throw new Error(`폴더 추가 실패: ${response.statusText}`);
    }
  } catch (error) {
    console.error("폴더 추가 중 에러 발생:", error);
    throw error;
  }
}

export async function updateKmsFolder(folder: folder_data) {
  console.log("backend.ts updateKmsFolder의 folder", folder);
  const tokenStore = useTokenStore();
  const response = await fetch(`${baseUrl.value}/kms/folder`, {
    method: "PUT",
    mode: "cors",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...tokenStore.createHeader()
    },
    body: JSON.stringify(folder)
  });
  if (response.ok) {
    // 추가 성공 처리
    console.log("폴더 업데이트 성공", folder);
    return (await response.json()) as folder_data;
  } else {
    // 추가 실패 처리
    console.error("폴더 업데이트 실패:", response.statusText);
    console.log(baseUrl.value);

    if (response.status === 401) {
      tokenStore.logOut();
    }
    if (response.status !== 200) {
      console.error(
        `Error updating channel: ${response.status} - ${response.statusText}`
      );
      const errorDetail = await response.json();
      console.error("Error detail:", errorDetail);
    }
  }
}

export interface ReqDeleteKmsFolder {
  kms_folder_key: number;
}
// 폴더 삭제
export async function deleteKmsFolder(
  kms_folder_key: number | { kms_folder_key: number }
) {
  // const tokenStore = useTokenStore();
  // object인 경우 값만 추출
  const folderKey =
    typeof kms_folder_key === "object"
      ? kms_folder_key.kms_folder_key
      : kms_folder_key;

  const response = await fetch(
    `${baseUrl.value}/kms/folder?param=${folderKey}`,
    {
      method: "DELETE",
      mode: "cors",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headers: {
        accept: "application/json",
        "Content-Type": "application/json"
        // ...tokenStore.createHeader()
      }
    }
  );

  console.log("response", response);

  if (response.ok) {
    // 추가 성공 처리
    console.log("폴더 삭제 성공");
    return (await response.json()) as ReqDeleteKmsFolder;
  } else {
    // 추가 실패 처리
    console.error("폴더 삭제 실패:", response.statusText);
    console.log(baseUrl.value);

    if (response.status === 401) {
      // tokenStore.logOut();
    }
  }
}

// kms 파일 추가
// export async function createKmsdoc(doc_data: Kmsdoc) {
// export async function createKmsdoc2(doc_data: any) {
//   // export async function createKmsdoc2(doc_data: FormData) {
//   try {
//     console.log("============================== 전송 데이터 확인:", doc_data);
//     const response = await fetch(`${baseUrl.value}/kms/doc`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         accept: "application/json"
//       },
//       body: JSON.stringify(doc_data) // JSON 데이터를 직렬화하여 전송
//     });

//     if (response.ok) {
//       const result = await response.json();
//       console.log("파일 추가 성공:", result);
//       return result;
//     } else {
//       const errorDetail = await response.text(); // 서버에서 반환된 에러 메시지 출력
//       console.error(
//         "파일 추가 실패:",
//         response.status,
//         response.statusText,
//         errorDetail
//       );
//       throw new Error(
//         `파일 추가 실패: ${response.statusText} - ${errorDetail}`
//       );
//     }
//   } catch (error) {
//     console.error("파일 추가 중 에러 발생:", error);
//     throw error;
//   }
// }

// 독 업데이트
// export interface requestKmsEmptyDoc {
//   kms_folder_key: number;
//   channel_key: number;
//   doc_name: string;
//   delete_yn: number;
//   doc_system_code: string;
// }

// createKmsdoc2: 지식 파일 생성
// 독 업데이트
export async function createKmsEmptyDoc(doc_data: any) {
  try {
    const response = await fetch(`${baseUrl.value}/kms/docEmpty`, {
      // const response = await fetch(`https://192.168.0.8:8000/kms/doc`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json"
      },
      body: JSON.stringify(doc_data)
    });

    if (response.ok) {
      const result = await response.json();
      return result; // 지식 생성 성공 시 결과 반환
    } else {
      const errorDetail = await response.text();
      throw new Error(`지식 생성 실패: ${errorDetail}`);
    }
  } catch (error) {
    console.error("지식 생성 중 오류 발생:", error);
    throw error;
  }
}

// createKmsdoc2: 지식 파일 생성
// 독 업데이트
export async function createKmsDoc2(doc_data: any) {
  try {
    const response = await fetch(`${baseUrl.value}/kms/doc2`, {
      // const response = await fetch(`https://192.168.0.8:8000/kms/doc`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json"
      },
      body: JSON.stringify(doc_data)
    });

    if (response.ok) {
      const result = await response.json();
      return result; // 지식 생성 성공 시 결과 반환
    } else {
      const errorDetail = await response.text();
      throw new Error(`지식 생성 실패: ${errorDetail}`);
    }
  } catch (error) {
    console.error("지식 생성 중 오류 발생:", error);
    throw error;
  }
}

export async function createFileList(formData: FormData) {
  try {
    const response = await fetch(`${baseUrl.value}/file/fileList`, {
      method: "POST",
      headers: {
        accept: "application/json"
      },
      body: formData
    });

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      const errorDetail = await response.text();
      throw new Error(`파일 업로드 실패: ${errorDetail}`);
    }
  } catch (error) {
    console.error("파일 업로드 중 오류 발생:", error);
    throw error;
  }
  //   if (response.ok) {
  //     const result = await response.json();
  //     console.log("======== createDocList test결과", result);

  //     // ✅ API 응답이 배열이므로 그대로 반환
  //     return result;
  //   } else {
  //     const errorDetail = await response.text();
  //     throw new Error(`파일 업로드 실패: ${errorDetail}`);
  //   }
  // } catch (error) {
  //   console.error("파일 업로드 중 오류 발생:", error);
  //   throw error;
  // }
}

export async function createRepoLinkList(linkData: {
  // kms_folder_key: number;
  channel_key: number;
  link_name: string;
  link_url: string;
  // delete_yn: number;
}) {
  try {
    const response = await fetch(`${baseUrl.value}/kms/repo/link`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json"
      },
      body: JSON.stringify(linkData)
    });

    if (response.ok) {
      const result = await response.json();
      return result; // repo_link_key를 포함한 데이터 반환
    } else {
      const errorDetail = await response.text();
      throw new Error(`링크 생성 실패: ${errorDetail}`);
    }
  } catch (error) {
    console.error("링크 생성 중 오류 발생:", error);
    throw error;
  }
}

// 독 업데이트
export async function getFileList(fileKeys: number) {
  try {
    const fileKeyStr = fileKeys.join(",");
    const response = await fetch(
      `${baseUrl.value}/file/?file_key_list_str=${encodeURIComponent(fileKeyStr)}`
    );
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("파일 정보 조회 실패");
    }
  } catch (error) {
    console.error("파일 정보 조회 중 오류:", error);
    throw error;
  }
}
// 독 업데이트
// export async function updateKmsDoc(doc_data: any) {
//   try {
//     const response = await fetch(`${baseUrl.value}/kms/doc`, {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json",
//         accept: "application/json"
//       },
//       body: JSON.stringify(doc_data)
//     });

//     if (response.ok) {
//       console.log("요약 수정 성공:", await response.json());
//       return true;
//     } else {
//       const errorDetail = await response.text();
//       console.error("요약 수정 실패:", response.status, errorDetail);
//       return false;
//     }
//   } catch (error) {
//     console.error("요약 수정 요청 중 오류 발생:", error);
//     return false;
//   }
// }

// kms 문서 수정
// 독 업데이트
export async function updateKmsDocManual(docData) {
  try {
    // FormData 생성
    const formData = new FormData();

    // 필수 필드 추가
    formData.append("kms_doc_key", docData.kms_doc_key);

    // 링크 목록 추가 (있는 경우)
    if (docData.link_str_list && docData.link_url_list) {
      docData.link_str_list.forEach((str, index) => {
        formData.append("link_str_list", str);
      });

      docData.link_url_list.forEach((url, index) => {
        formData.append("link_url_list", url);
      });
    }

    // 파일 목록 추가 (있는 경우)
    if (docData.body_file_list) {
      docData.body_file_list.forEach((file) => {
        formData.append("body_file_list", file);
      });
    }

    if (docData.attach_file_list) {
      docData.attach_file_list.forEach((file) => {
        formData.append("attach_file_list", file);
      });
    }

    if (docData.img_file_list) {
      docData.img_file_list.forEach((file) => {
        formData.append("img_file_list", file);
      });
    }

    const response = await fetch(`${baseUrl.value}/kms/updateKmsDocManual`, {
      method: "POST",
      body: formData // FormData 사용으로 'Content-Type' 헤더는 자동 설정됨
    });

    if (response.ok) {
      const result = await response.json();
      console.log("문서 매뉴얼 업데이트 성공:", result);
      return result;
    } else {
      const errorDetail = await response.text();
      console.error("문서 매뉴얼 업데이트 실패:", response.status, errorDetail);
      throw new Error(`문서 매뉴얼 업데이트 실패: ${errorDetail}`);
    }
  } catch (error) {
    console.error("문서 매뉴얼 업데이트 요청 중 오류 발생:", error);
    throw error;
  }
}

// 독 업데이트
// kms 지식 추가
export async function createKmsKnow(know_data: any) {
  try {
    console.log("createKmsKnow 전송 데이터 확인:", know_data);
    const response = await fetch(`${baseUrl.value}/kms/know`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json"
      },
      body: JSON.stringify(know_data)
    });

    if (response.ok) {
      const result = await response.json();
      console.log("파일 추가 성공:", result);
      return result;
    } else {
      const errorDetail = await response.text(); // 서버에서 반환된 에러 메시지 출력
      console.error(
        "파일 추가 실패:",
        response.status,
        response.statusText,
        errorDetail
      );
      throw new Error(
        `파일 추가 실패: ${response.statusText} - ${errorDetail}`
      );
    }
  } catch (error) {
    console.error("파일 추가 중 에러 발생:", error);
    throw error;
  }
}

// KMS 지식 삭제
export async function deleteKmsKnow(kms_know_key: number) {
  console.log("backend", kms_know_key);
  const response = await fetch(
    `${baseUrl.value}/kms/know?kms_know_key=${kms_know_key}`,
    {
      method: "DELETE",
      mode: "cors",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headers: {
        accept: "application/json",
        "Content-Type": "application/json"
        // ...tokenStore.createHeader()
      }
    }
  );

  if (response.ok) {
    // 추가 성공 처리
    console.log("지식 삭제 성공");
    return await response.json();
  } else {
    // 추가 실패 처리
    console.error("지식 삭제 실패:", response.statusText);
    console.log(baseUrl.value);

    // if (response.status === 401) {
    //   tokenStore.logOut();
    // }
  }
}

// PDF 전용 API 호출 함수
export async function requestPdfToKnow(formData: FormData) {
  try {
    console.log("PDF 지식 추가 요청 데이터 확인:", formData);
    const response = await fetch(`${baseUrl.value}/kms/requst_pdf_to_know`, {
      method: "POST",
      body: formData
    });

    if (response.ok) {
      const result = await response.json();
      console.log("PDF 지식 추가 성공:", result);
      return result;
    } else {
      const errorDetail = await response.text();
      console.error(
        "PDF 지식 추가 실패:",
        response.status,
        response.statusText,
        errorDetail
      );
      throw new Error(
        `PDF 지식 추가 실패: ${response.statusText} - ${errorDetail}`
      );
    }
  } catch (error) {
    console.error("PDF 지식 추가 중 에러 발생:", error);
    throw error;
  }
}

// export async function createKmsDoc(doc_data: KmsDoc) {
// export async function createKmsDoc2(doc_data: any) {
//   try {
//     console.log("전송 데이터 확인:", doc_data);
//     // const response = await fetch(`${baseUrl.value}/kms/doc`, {
//     // const response = await fetch("https://anytalk.com:8082/kms/doc", {
//     const response = await fetch(`https://192.168.0.6:8000/kms/doc2`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         accept: "application/json"
//       },
//       body: JSON.stringify(doc_data)
//     });

//     if (response.ok) {
//       const result = await response.json();
//       console.log("파일 추가 성공:", result);
//       return result;
//     } else {
//       const errorDetail = await response.text(); // 서버에서 반환된 에러 메시지 출력
//       console.error(
//         "파일 추가 실패:",
//         response.status,
//         response.statusText,
//         errorDetail
//       );
//       throw new Error(
//         `파일 추가 실패: ${response.statusText} - ${errorDetail}`
//       );
//     }
//   } catch (error) {
//     console.error("파일 추가 중 에러 발생:", error);
//     throw error;
//   }
// }

// kms 파일 수정
export async function updateKmsKnow(data: UpdateKmsKnowRequest) {
  try {
    const response = await fetch(`${baseUrl.value}/kms/know`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json"
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      console.log("지식 수정 성공:", await response.json());
      return true;
    } else {
      const errorDetail = await response.text();
      console.error("지식 수정 실패:", response.status, errorDetail);
      return false;
    }
  } catch (error) {
    console.error("지식 수정 요청 중 오류 발생:", error);
    return false;
  }
}

export interface confirmKmsKnowReq {
  kms_know_key: number;
  confirm_yn: number;
}
export interface confirmKmsKnowReq2 {
  kms_know_key: number;
  // 독 업데이트
  // kms_doc_key: number;
  kms_folder_key?: number;
  channel_key: number;
  lc_know_id?: string;
  confirm_yn: number;
  title: string;
  summary: string;
  body: string;
  must_text: string;
  keyword_list: string[];
  quest_list: string[];
  img_filekey_list: number[];
  attach_filekey_list: number[];
  linkkey_list: number[];
  delete_yn: number;
  embedding?: string;
  confirm_date?: Date;
  upd_user_key?: number;
  upd_date?: Date;
  refine_yn?: number;
  refine_date?: Date;
  mapping_key_str?: string;
  valid_yn?: number;
}

// kms 지식 업데이트 로직
export async function confirmKmsKnow(data: confirmKmsKnowReq) {
  console.log("confirmKmsKnow 실행!");

  // 값이 undefined인 경우 기본값 설정
  const kmsKnowKey = data.kms_know_key?.toString() || "unknown";
  const confirmYn = data.confirm_yn?.toString() || "0";

  try {
    const queryParams = new URLSearchParams({
      kms_know_key: kmsKnowKey,
      confirm_yn: confirmYn
    }).toString();

    const response = await fetch(
      `${baseUrl.value}/kms/know/confirm?${queryParams}`,
      {
        method: "PUT",
        headers: {
          accept: "application/json"
        }
      }
    );

    if (response.ok) {
      console.log("요약 수정 성공:", await response.json());
      return true;
    } else {
      const errorDetail = await response.text();
      console.error("요약 수정 실패:", response.status, errorDetail);
      return false;
    }
  } catch (error) {
    console.error("요약 수정 요청 중 오류 발생:", error);
    return false;
  }
}

export async function confirmKmsKnow2(data: confirmKmsKnowReq2) {
  console.log("confirmKmsKnow 실행!");

  try {
    // 객체를 쿼리 문자열로 변환
    // const requestBody = {
    //   kms_know_key: data.kms_know_key?.toString() || "0",
    //   kms_doc_key: data.kms_doc_key?.toString() || "0",
    //   channel_key: data.channel_key?.toString() || "0",
    //   confirm_yn: data.confirm_yn?.toString() || "0",
    //   lc_know_id: data.lc_know_id?.toString() || "",
    //   title: data.title?.toString() || "",
    //   summary: data.summary?.toString() || "",
    //   body: data.body?.toString() || "",
    //   must_text: data.must_text?.toString() || "",
    //   delete_yn: data.delete_yn?.toString() || "0",
    //   keyword_list: data.keyword_list || [],
    //   img_filekey_list: data.img_filekey_list || [],
    //   attach_filekey_list: data.attach_filekey_list || [],
    //   linkkey_list: data.linkkey_list || []
    // };
    // const requestBody = {
    //   kms_know_key: data.kms_know_key ?? 0,
    //   kms_doc_key: data.kms_doc_key ?? 0,
    //   kms_folder_key: data.kms_folder_key ?? 0,
    //   channel_key: data.channel_key ?? 0,
    //   lc_know_id: data.lc_know_id ?? "",
    //   confirm_yn: data.confirm_yn ?? 0,
    //   title: data.title ?? "",
    //   summary: data.summary ?? "",
    //   body: data.body ?? "",
    //   must_text: data.must_text ?? "",
    //   delete_yn: data.delete_yn ?? 0,
    //   keyword_list: Array.isArray(data.keyword_list) ? data.keyword_list : [],
    //   img_filekey_list: Array.isArray(data.img_filekey_list)
    //     ? data.img_filekey_list
    //     : [],
    //   attach_filekey_list: Array.isArray(data.attach_filekey_list)
    //     ? data.attach_filekey_list
    //     : [],
    //   linkkey_list: Array.isArray(data.linkkey_list) ? data.linkkey_list : [],
    //   embedding: data.embedding ?? ""
    // };

    const requestBody = {
      kms_know_key: Number(data.kms_know_key) || 0,
      // 독 업데이트
      // kms_doc_key: Number(data.kms_doc_key) || 0,
      kms_folder_key: Number(data.kms_folder_key) || 0, // 누락된 필드 추가
      channel_key: Number(data.channel_key) || 0,
      // lc_know_id: data.lc_know_id || "3fa85f64-5717-4562-b3fc-2c963f66afa6", // 기본 UUID 값 설정
      confirm_yn: Number(data.confirm_yn) || 0,
      title: data.title || "",
      summary: data.summary || "",
      body: data.body || "",
      must_text: data.must_text || "",
      delete_yn: Number(data.delete_yn) || 0,
      keyword_list: Array.isArray(data.keyword_list) ? data.keyword_list : [],
      quest_list: Array.isArray(data.quest_list) ? data.quest_list : [],
      img_filekey_list: Array.isArray(data.img_filekey_list)
        ? data.img_filekey_list.map(Number)
        : [0], // 숫자 배열로 변환
      attach_filekey_list: Array.isArray(data.attach_filekey_list)
        ? data.attach_filekey_list.map(Number)
        : [0],
      linkkey_list: Array.isArray(data.linkkey_list)
        ? data.linkkey_list.map(Number)
        : [0],
      confirm_date: new Date().toISOString(), // 현재 날짜 적용
      upd_user_key: Number(data.upd_user_key) || 0,
      upd_date: new Date().toISOString(),
      refine_yn: Number(data.refine_yn) || 0,
      refine_date: new Date().toISOString(),
      // mapping_key_str: data.mapping_key_str || "string",
      mapping_key_str: data.mapping_key_str,
      valid_yn: Number(data.valid_yn)
    };

    const response = await fetch(`${baseUrl.value}/kms/know/confirm2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json"
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      const errorDetail = await response.text();
      throw new Error(`요약 수정 실패: ${response.status} - ${errorDetail}`);
    }

    const result = await response.json();
    console.log("요약 수정 성공:", result);
    return result;
  } catch (error) {
    console.error("요약 수정 요청 중 오류 발생:", error);
    throw error;
  }
}

// 파일 삭제
// 독 업데이트
// export async function deleteKmsDoc(kms_doc_key: number) {
//   // const tokenStore = useTokenStore();
//   const response = await fetch(
//     `${baseUrl.value}/kms/doc?param=${kms_doc_key}`,
//     {
//       method: "DELETE",
//       mode: "cors",
//       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//       // @ts-ignore
//       headers: {
//         accept: "application/json",
//         "Content-Type": "application/json"
//         // ...tokenStore.createHeader()
//       }
//     }
//   );

//   if (response.ok) {
//     // 추가 성공 처리
//     console.log("파일 삭제 성공");
//     return await response.json();
//   } else {
//     // 추가 실패 처리
//     console.error("파일 삭제 실패:", response.statusText);
//     console.log(baseUrl.value);

//     // if (response.status === 401) {
//     //   tokenStore.logOut();
//     // }
//   }
// }

// kms repo image 추가
export async function createRepoFile(
  formData: FormData
): Promise<KmsRepoImage> {
  try {
    const response = await fetch(`${baseUrl.value}/kms/repo/file`, {
      method: "POST",
      headers: {
        accept: "application/json"
      },
      body: formData
    });

    if (response.ok) {
      const result = await response.json();
      console.log("파일 업로드 성공:", result);
      return result;
    } else {
      throw new Error("파일 업로드 실패");
    }
  } catch (error) {
    console.error("파일 업로드 중 에러 발생:", error);
    throw error;
  }
}

export async function createPreAfunTitle(know_data: any) {
  try {
    console.log("전송 데이터 확인:", know_data);

    const url = new URL(`${baseUrl.value}/kms/request_at_title`);
    url.searchParams.append("source_text", know_data.source_text);

    const response = await fetch(url.toString(), {
      // const response = await fetch("https://anytalk.com:8082/kms/doc", {
      // const response = await fetch(`https://192.168.0.6:8000/kms/doc2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json"
      },
      body: JSON.stringify(know_data)
    });

    if (response.ok) {
      const result = await response.json();
      console.log("AFUN 처리 성공:", result);
      return result;
    } else {
      const errorDetail = await response.text(); // 서버에서 반환된 에러 메시지 출력
      console.error(
        "AFUN 실패:",
        response.status,
        response.statusText,
        errorDetail
      );
      throw new Error(`AFUN 실패: ${response.statusText} - ${errorDetail}`);
    }
  } catch (error) {
    console.error("AFUN 처리 중 에러 발생:", error);
    throw error;
  }
}

export async function createPreAfunTotalSummary(know_data: any) {
  try {
    console.log("전송 데이터 확인:", know_data);

    const url = new URL(`${baseUrl.value}/kms/request_at_total_summary2`);
    url.searchParams.append("source_text", know_data.source_text);
    url.searchParams.append("channel_key", know_data.channel_key);
    // console.log("url", url);
    // console.log("url.toString()", url.toString());

    const response = await fetch(url.toString(), {
      // const response = await fetch("https://anytalk.com:8082/kms/doc", {
      // const response = await fetch(`https://192.168.0.6:8000/kms/doc2`, {
      method: "POST",
      // mode: "cors",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json"
      },
      body: JSON.stringify(know_data)
    });

    if (response.ok) {
      const result = await response.json();
      console.log("AFUN 처리 성공:", result);
      return result;
    } else {
      const errorDetail = await response.text(); // 서버에서 반환된 에러 메시지 출력
      console.error(
        "AFUN 실패:",
        response.status,
        response.statusText,
        errorDetail
      );
      throw new Error(`AFUN 실패: ${response.statusText} - ${errorDetail}`);
    }
  } catch (error) {
    console.error("AFUN 처리 중 에러 발생:", error);
    throw error;
  }
}

//=========================== bert / bart

export async function getBertSummary(formData: FormData): Promise<any> {
  console.log("getBertSummary API 호출");
  const response = await fetch(
    // "https://anytalk.kr:8004/api/get_AI_refine_test2",
    // "https://175.212.203.220:8004/api/get_AI_refine_test2",
    "https://api.edupl.kr:8004/api/get_AI_refine_test2",
    {
      method: "POST",
      body: formData
    }
  );

  if (response.ok) {
    const result = await response.json();
    return result;
  } else {
    console.error("BERT API 요청 실패:", response.statusText);
    throw new Error(`API Error: ${response.statusText}`);
  }
}

// export async function requestAiResult(formData: FormData): Promise<any> {
//   console.log("bert/bart API 호출 request");
//   const response = await fetch(`${baseUrl.value}/kms/doc`, {
//     method: "POST",
//     body: formData
//   });
// }

// =========================== createfile 테스트

export async function testMultiFile(formData: FormData, file_name: string) {
  try {
    const fileName = file_name;
    const encodedFileName = encodeURIComponent(fileName);
    const response = await fetch(
      `${baseUrl.value}/kms/testMultiFile?fileName=${encodedFileName}`,

      {
        method: "POST",
        headers: {
          accept: "application/json"
        },
        body: formData
      }
    );

    if (response.ok) {
      const result = await response.json();
      return result; // 업로드 성공 시 fileKey 반환
    } else {
      const errorDetail = await response.text();
      throw new Error(`파일 업로드 실패: ${errorDetail}`);
    }
  } catch (error) {
    console.error("파일 업로드 중 오류 발생:", error);
    throw error;
  }
}

export async function testMultiFileList(formData: FormData, file_name: string) {
  try {
    const fileName = file_name;
    const encodedFileName = encodeURIComponent(fileName);

    const response = await fetch(
      `${baseUrl.value}/kms/testMultiFileList?fileName=${encodedFileName}`,
      {
        method: "POST",
        headers: {
          accept: "application/json"
        },
        body: formData // FormData로 파일 배열 전송
      }
    );

    if (response.ok) {
      const result = await response.json();
      return result; // 업로드 성공 시 결과 반환
    } else {
      const errorDetail = await response.text();
      throw new Error(`파일 업로드 실패: ${errorDetail}`);
    }
  } catch (error) {
    console.error("파일 업로드 중 오류 발생:", error);
    throw error;
  }
}

export async function testCreateFileList(formData: FormData) {
  try {
    console.log("testCreateFileList======= 시작");
    // const response = await fetch(`https://192.168.0.8:8000/file/fileList`, {
    const response = await fetch(`${baseUrl.value}/file/fileList`, {
      method: "POST",
      headers: {
        accept: "application/json"
      },
      body: formData
    });

    if (response.ok) {
      const result = await response.json();
      console.log("======== createFileList test결과", result);

      // ✅ API 응답이 배열이므로 그대로 반환
      return result;
    } else {
      const errorDetail = await response.text();
      throw new Error(`파일 업로드 실패: ${errorDetail}`);
    }
  } catch (error) {
    console.error("파일 업로드 중 오류 발생:", error);
    throw error;
  }
}

// kms 지식 추가
export async function aiRefineChannel(queryParams: Record<string, any>) {
  try {
    console.log("aiRefineChannel 전송 데이터 확인:", queryParams);

    // queryParams 객체를 URLSearchParams로 변환
    const queryString = new URLSearchParams(queryParams).toString();

    const response = await fetch(
      `${baseUrl.value}/kms/ai_refine_channel?${queryString}`,
      {
        method: "POST",
        headers: {
          accept: "application/json"
        }
      }
    );

    if (response.ok) {
      const result = await response.json();
      console.log("파일 추가 성공:", result);
      return result;
    } else {
      const errorDetail = await response.text();
      console.error(
        "파일 추가 실패:",
        response.status,
        response.statusText,
        errorDetail
      );
      throw new Error(
        `파일 추가 실패: ${response.statusText} - ${errorDetail}`
      );
    }
  } catch (error) {
    console.error("파일 추가 중 에러 발생:", error);
    throw error;
  }
}

// 지식 원본 보기
// export async function getKmsKnowReason(msg_key: number, reason_key?: number) {
//   const response = await fetch(
//     `${baseUrl.value}/kms/reason?reason_key=${reason_key}&msg_key=${msg_key}`,
//     {
//       method: "GET",
//       mode: "cors",
//       headers: {
//         accept: "application/json"
//       }
//     }
//   );

//   if (response.ok) {
//     const kmsKnowList = await response.json();
//     console.log("kms 지식 원본 데이터 불러오기:", kmsKnowList);
//     return kmsKnowList;
//   } else {
//     console.error("kms 지식 원본 데이터 불러오기 실패:", response.statusText);
//     return [];
//   }
// }

export async function getKmsKnowReason(msg_key: number, reason_key?: number) {
  // URLSearchParams를 사용하여 쿼리스트링 생성
  const queryParams = new URLSearchParams();

  // msg_key는 항상 포함
  queryParams.append("msg_key", msg_key.toString());

  // reason_key가 있는 경우에만 추가
  if (reason_key !== undefined) {
    queryParams.append("reason_key", reason_key.toString());
  }

  const response = await fetch(
    `${baseUrl.value}/kms/reason?${queryParams.toString()}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        accept: "application/json"
      }
    }
  );

  if (response.ok) {
    const kmsKnowList = await response.json();
    console.log("kms 지식 원본 데이터 불러오기:", kmsKnowList);
    return kmsKnowList;
  } else {
    console.error("kms 지식 원본 데이터 불러오기 실패:", response.statusText);
    return [];
  }
}

//=========================== bert / bart

export interface ResChannelList {
  channel_list: Channel[];
  auth_user_list: AuthUser[];
}
export interface ReqGetChannelList {
  channel_key?: number;
  // channel_name?: string;
  // own_user_key?: number;
  // auth_user_key?: number;
  user_key?: number;
}

// 멤버목록 불러오기
export async function getChannelMemberList(param: ReqGetChannelList = {}) {
  const response = await fetch(
    // `${baseUrl.value}/channel?channel_key=${channelKey}`,
    `${baseUrl.value}/channel/member/${createQueryString(param)}`,
    {
      mode: "cors"
    }
  );

  if (response.ok) {
    // 추가 성공 처리
    console.log("채널 목록 로드 성공");
    console.log("고정 Url값=============", import.meta.env.VITE_BASE_URL);
    console.log("현재 BaseUrl값=============", baseUrl.value);
    return (await response.json()) as AuthChannel[];
  } else {
    // 추가 실패 처리
    console.error("채널 목록 로드 실패:", response.statusText);
    console.log("고정 Url값=============", import.meta.env.VITE_BASE_URL);
    console.log("현재 BaseUrl값=============", baseUrl.value);
  }
}

//=========================== User
export interface ReqGetUserList {
  channel_key: number;
  auth_type: string;
  user_key: number;
  user_id: string;
  phone4: string;
  user_name: string;
  user_nick: string;
}

export async function getUserList(param: ReqGetUserList) {
  try {
    const response = await fetch(
      `${baseUrl.value}/user/${createQueryString(param)}`,
      { mode: "cors" }
    );

    if (!response.ok) {
      throw new Error(
        `HTTP 오류! 상태 코드: ${response.status} - ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("유저 목록 가져오기 오류:", error);
    return []; // 오류 발생 시 빈 배열 반환
  }
}

//=========================== AuthCard
export interface ReqGetAuthCardList {
  subinfo_yn: number;
  authcard_key: number;
  channel_key: number;
  folder_key_list_str: string;
}

export async function getAuthCardList(param: ReqGetAuthCardList) {
  try {
    const response = await fetch(
      `${baseUrl.value}/channel/authcard/${createQueryString(param)}`,
      { mode: "cors" }
    );

    if (!response.ok) {
      throw new Error(
        `HTTP 오류! 상태 코드: ${response.status} - ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("카드 목록 가져오기 오류:", error);
    return []; // 오류 발생 시 빈 배열 반환
  }
}

export interface auth_card_data {
  authcard_key: number;
  authcard_name: string;
  channel_key: number;
  folder_key_list: number[];
  delete_yn: number;
}

export async function createAuthCard(auth_card_data: auth_card_data) {
  try {
    const response = await fetch(`${baseUrl.value}/channel/authcard`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json"
      },
      body: JSON.stringify(auth_card_data)
    });

    if (response.ok) {
      const result = await response.json();
      console.log("지식카드 추가 성공:", response);
      return result;
    } else {
      console.error("지식카드 추가 실패:", response.statusText);
      throw new Error(`지식카드 추가 실패: ${response.statusText}`);
    }
  } catch (error) {
    console.error("지식카드 추가 중 에러 발생:", error);
    throw error;
  }
}

// 권한 카드 수정
export async function updateAuthCard(auth_card_data: auth_card_data) {
  try {
    const response = await fetch(`${baseUrl.value}/channel/authcard`, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json"
      },
      body: JSON.stringify(auth_card_data)
    });

    if (response.ok) {
      const result = await response.json();
      console.log("권한 카드 수정 성공:", response);
      return result;
    } else {
      console.error("권한 카드 수정 실패:", response.statusText);
      throw new Error(`권한 카드 수정 실패: ${response.statusText}`);
    }
  } catch (error) {
    console.error("권한 카드 수정 중 에러 발생:", error);
    throw error;
  }
}

// 권한 카드 삭제
export async function deleteAuthCard(auth_card_key: number) {
  try {
    const response = await fetch(
      `${baseUrl.value}/channel/authcard?authcard_key=${auth_card_key}`,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json"
        }
      }
    );

    if (response.ok) {
      const result = await response.json();
      console.log("권한 카드 삭제 성공", result);
      return result;
    } else {
      console.error("권한 카드 삭제 실패", response.statusText);
      throw new Error(`권한 카드 삭제 실패 ${response.statusText}`);
    }
  } catch (error) {
    console.error("권한 카드 삭제 중 에러 발생", error);
    throw error;
  }
}

export interface auth_user_data {
  auth_user_key: number;
  user_key: number;
  channel_key: number;
  auth_type: string;
  authcard_key: number;
}

export async function updateChannelAuthUser(auth_user_data: auth_user_data) {
  try {
    const response = await fetch(
      `${baseUrl.value}/channel/updateChannelAuthUser`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json"
        },
        body: JSON.stringify(auth_user_data)
      }
    );

    if (response.ok) {
      const result = await response.json();
      console.log("유저정보 수정 성공:", response);
      return result;
    } else {
      console.error("유저정보 수정 실패:", response.statusText);
      throw new Error(`유저정보 수정 실패: ${response.statusText}`);
    }
  } catch (error) {
    console.error("유저정보 수정 중 에러 발생:", error);
    throw error;
  }
}

export async function prepareAuthUser(queryParams: Record<string, any>) {
  try {
    console.log("prepareAuthUser 전송 데이터 확인:", queryParams);

    // queryParams 객체를 URLSearchParams로 변환하여 URL에 추가
    const queryString = new URLSearchParams(queryParams).toString();

    const response = await fetch(
      `${baseUrl.value}/user/prepareAuthUser?${queryString}`,
      {
        method: "POST",
        headers: {
          accept: "application/json"
        }
      }
    );

    if (response.ok) {
      const result = await response.json();
      console.log("유저 생성 성공:", result);
      return result;
    } else {
      const errorDetail = await response.text();
      console.error(
        "유저 생성 실패:",
        response.status,
        response.statusText,
        errorDetail
      );
      throw new Error(
        `유저 생성 실패: ${response.statusText} - ${errorDetail}`
      );
    }
  } catch (error) {
    console.error("유저 생성 중 에러 발생:", error);
    throw error;
  }
}

//=========================== SSO USer
// export async function getUserInfo() {
//   try {
//     const response = await fetch(`${baseUrl.value}/channel/userInfo`, {
//       mode: "cors",
//       withCredentials: true
//     });

//     if (!response.ok) {
//       console.error("❌ 유저 정보 없음:", response);
//       return;
//     }
//     sessionStorage.setItem("user_info", JSON.stringify(response.user));

//     return await response.json();
//   } catch (error) {
//     console.error("카드 목록 가져오기 오류:", error);
//     return []; // 오류 발생 시 빈 배열 반환
//   }
// }
// // ✅ 자동 로그인 함수
// const autoLogin = async (user) => {
//   try {
//     const credentials = {
//       username: user.username,
//       password: user.user_id // SSO에서 받은 user_id를 비밀번호로 사용
//     };
//     const result = await loginIdPw(credentials);
//     if (result) {
//       store.setUserFromLoginResult(result);
//       console.log("✅ 자동 로그인 성공!");
//     }
//   } catch (error) {
//     console.error("❌ 자동 로그인 실패:", error);
//   }
// };

// // ✅ Vue.js가 로드될 때 FastAPI에서 유저 정보를 가져옴
// onMounted(fetchUserInfo);

//=========================== 추가된 Move API

// export async function moveKmsFolder(
//   srcFolderKey: number,
//   toParentFolderKey: number
// ) {
//   console.log("moveKmsFolder 요청:", { srcFolderKey, toParentFolderKey });

//   const tokenStore = useTokenStore();
//   const response = await fetch(
//     `${baseUrl.value}/kms/moveFolder?srcFolderKey=${srcFolderKey}&toParentFolderKey=${toParentFolderKey}`, // ✅ 쿼리 파라미터로 전송
//     {
//       method: "PUT",
//       mode: "cors",
//       headers: {
//         accept: "application/json",
//         "Content-Type": "application/json",
//         ...tokenStore.createHeader()
//       }
//     }
//   );

//   if (response == null) {
//     console.log("폴더 이동 성공:", { srcFolderKey, toParentFolderKey });
//     return await response.json();
//   } else {
//     console.error("폴더 이동 실패:", response.statusText);
//     console.log(baseUrl.value);

//     if (response.status === 401) {
//       tokenStore.logOut();
//     }

//     if (response.status !== 200) {
//       console.error(
//         `Error updating folder: ${response.status} - ${response.statusText}`
//       );
//       const errorDetail = await response.json();
//       console.error("Error detail:", errorDetail);
//     }
//   }
// }
export async function moveKmsFolder(
  srcFolderKey: number,
  toParentFolderKey: number
) {
  console.log("moveKmsFolder 요청:", { srcFolderKey, toParentFolderKey });

  const tokenStore = useTokenStore();
  const response = await fetch(
    `${baseUrl.value}/kms/moveFolder?srcFolderKey=${srcFolderKey}&toParentFolderKey=${toParentFolderKey}`,
    {
      method: "PUT",
      mode: "cors",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        ...tokenStore.createHeader()
      }
    }
  );

  if (!response.ok) {
    console.error("폴더 이동 실패:", response.statusText);
    console.log(baseUrl.value);

    if (response.status === 401) {
      tokenStore.logOut();
    }

    const errorDetail = await response.json();
    console.error("Error detail:", errorDetail);
    throw new Error(
      `폴더 이동 실패: ${errorDetail.message || response.statusText}`
    );
  }

  console.log("폴더 이동 성공:", { srcFolderKey, toParentFolderKey });
  return response;
}

// 독 업데이트
// export async function moveKmsDoc(srcDocKey: number, toFolderKey: number) {
//   console.log("moveKmsDoc 요청:", { srcDocKey, toFolderKey });

//   const tokenStore = useTokenStore();
//   const response = await fetch(
//     `${baseUrl.value}/kms/moveDoc?srcDocKey=${srcDocKey}&toFolderKey=${toFolderKey}`, // ✅ 쿼리 파라미터 방식 적용
//     {
//       method: "PUT",
//       mode: "cors",
//       headers: {
//         accept: "application/json",
//         "Content-Type": "application/json",
//         ...tokenStore.createHeader()
//       }
//     }
//   );

//   if (response.ok) {
//     console.log("문서 이동 성공:", { srcDocKey, toFolderKey });
//     return await response.json();
//   } else {
//     console.error("문서 이동 실패:", response.statusText);
//     console.log(baseUrl.value);

//     if (response.status === 401) {
//       tokenStore.logOut();
//     }

//     if (response.status !== 200) {
//       console.error(
//         `Error moving document: ${response.status} - ${response.statusText}`
//       );
//       const errorDetail = await response.json();
//       console.error("Error detail:", errorDetail);
//     }
//   }
// }

// 독 업데이트
export async function moveKmsKnow(srcKnowKey: number, toFolderKey: number) {
  console.log("moveKmsKnow 요청:", { srcKnowKey, toFolderKey });
  const tokenStore = useTokenStore();
  // const response = await fetch(
  //   `${baseUrl.value}/kms/moveKnow?srcKnowKey=${srcKnowKey}&toDocKey=${toDocKey}`, // ✅ 쿼리 파라미터 방식 적용
  //   {
  const response = await fetch(
    // `${baseUrl.value}/kms/moveKnow?srcKnowKey=${srcKnowKey}&toFolderKey=${toFolderKey}`, // ✅ 쿼리 파라미터 방식 적용
    // 독 업데이트 명칭 약속 다름 toDocKey -> toFolderKey
    `${baseUrl.value}/kms/moveKnow?srcKnowKey=${srcKnowKey}&toDocKey=${toFolderKey}`, // ✅ 쿼리 파라미터 방식 적용
    {
      method: "PUT",
      mode: "cors",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        ...tokenStore.createHeader()
      }
    }
  );

  if (response.ok) {
    console.log("지식 이동 성공:", { srcKnowKey, toFolderKey });
    return await response.json();
  } else {
    console.error("지식 이동 실패:", response.statusText);
    console.log(baseUrl.value);

    if (response.status === 401) {
      tokenStore.logOut();
    }

    if (response.status !== 200) {
      console.error(
        `Error moving knowledge: ${response.status} - ${response.statusText}`
      );
      const errorDetail = await response.json();
      console.error("Error detail:", errorDetail);
    }
  }
}

// ================== 메세지 보관함 북마크 ===================
// 타입 정의
export interface SavedMessage {
  save_key: number;
  ask_str: string;
  folder_key: number;
  cre_user_key: number;
  delete_yn: number;
  res_msg_key: number;
  channel_key: number;
  res_str: string;
  cre_date: string;
}

export interface Folder {
  folder_key: number;
  name: string;
  cre_user_key: number;
  cre_date: string;
}

export interface ReqGetSavedMessages {
  user_key?: number;
  folder_key?: number | null;
  delete_yn?: number | null;
}

export interface ReqPostSaveMessages {
  user_key: number;
  res_msg_key: number;
  folder_key?: number;
}

export interface ReqCreateFolder {
  user_key?: number;
  folder_name: string;
}

export interface ReqUpdateSavedMessage {
  delete_yn: number;
}

// 저장된 메시지 목록 가져오기
export async function getSavedMessages(param: ReqGetSavedMessages = {}) {
  const tokenStore = useTokenStore();
  const response = await fetch(
    `${baseUrl.value}/kms/kmsSave${createQueryString(param)}`,
    {
      mode: "cors",
      headers: {
        accept: "application/json",
        ...tokenStore.createHeader()
      }
    }
  );

  if (response.ok) {
    console.log("저장된 메시지 로드 성공");
    return (await response.json()) as SavedMessage[];
  } else {
    console.error("저장된 메시지 로드 실패:", response.statusText);
    throw new Error(response.statusText);
  }
}

export async function createKmsSavedMessages(param: ReqPostSaveMessages) {
  try {
    const queryParams = new URLSearchParams();
    queryParams.append("user_key", param.user_key.toString());
    queryParams.append("res_msg_key", param.res_msg_key.toString());
    if (param.folder_key !== undefined) {
      queryParams.append("folder_key", param.folder_key.toString());
    }

    const response = await fetch(
      `${baseUrl.value}/kms/kmsSave?${queryParams.toString()}`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          accept: "application/json"
        }
      }
    );

    if (response.ok) {
      const result = await response.json();
      console.log("메시지 저장 성공");
      return result;
    } else {
      console.error("메시지 저장 실패", response.statusText);
      throw new Error(`지식카드 추가 실패: ${response.statusText}`);
    }
  } catch (error) {
    console.error("메세지 저장 중 에러 발생:", error);
    throw error;
  }
}
