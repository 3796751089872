<!-- KmsKnowItemAdd.vue -->
<script setup lang="ts">
import { ref, computed, nextTick } from "vue";
import { useStore } from "../../../stores/store";
import {
  createFileList,
  createKmsKnow,
  createPreAfunTitle,
  createPreAfunTotalSummary,
  createRepoLinkList,
  requestPdfToKnow
} from "../../../api/backend";
import KmsFileRepoUploader from "./KmsFileRepoUploader.vue";
import { useQuasar } from "quasar";
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_24_regular.svg";
import TextIcon from "@fluentui/svg-icons/icons/text_field_20_regular.svg";
import FileDownload from "@fluentui/svg-icons/icons/cloud_arrow_down_20_regular.svg";
import * as pdfjsLib from "pdfjs-dist";
import { SwitchRoot, SwitchThumb } from "radix-vue";

// pdf.worker 설정
pdfjsLib.GlobalWorkerOptions.workerSrc = "/assets/pdf.worker.min.js";

const emit = defineEmits(["close", "pdfProcessingStart"]);
const store = useStore();
const $q = useQuasar();

// 입력 필드 상태 관리
const title = ref("");
const summary = ref("");
const body = ref("");
const mustText = ref("");
const keywords = ref("");
const questList = ref("");
const imageFiles = ref([]);
const attachFiles = ref([]);

// 텍스트 탭 데이터 관리
const textData = ref({
  title: "",
  summary: "",
  body: "",
  mustText: "",
  keywords: "",
  questList: "",
  previewLinks: [] as { text: string; url: string }[],
  linkKeys: [] as number[],
  imgFileKeys: [] as number[],
  attachFileKeys: [] as number[],
  showGeneratedFields: false,
  subOptionYn: false
});

// PDF 탭 데이터 관리 - 단순화된 버전으로 필요한 필드만 포함
const pdfData = ref({
  uploadedBodyFile: null as File | null,
  bodyFilePreview: null as { name: string; url: string } | null,
  pageRangeStr: "", // 페이지 범위 문자열 (예: "1-5,8,10-15")
  pageChunkYn: 1, // 페이지 청크 유무 (1: 사용, 0: 미사용)
  file_key: null as number | null
});

// const links = ref("");
const switchState = ref(false);

// 탭 상태 관리
const activeTab = ref("text");

// AI 생성 결과 표시 상태
const showGeneratedFields = ref(false);

const isGenerating = ref(false);

const props = defineProps<{
  channelKey: number | string; // 부모로부터 전달받을 채널키
}>();

// 파일 업로드 핸들러
// const handleImageUpload = (event: Event) => {
//   const target = event.target as HTMLInputElement;
//   if (target.files) {
//     imageFiles.value = Array.from(target.files);
//   }
// };

// const handleAttachUpload = (event: Event) => {
//   const target = event.target as HTMLInputElement;
//   if (target.files) {
//     attachFiles.value = Array.from(target.files);
//   }
// };

// ==================================================
// 팝업 관련 함수
// ==================================================

// 팝업 닫기
const closePopup = () => {
  emit("close");
};

// ==================================================
// PDF 탭 관련 함수
// ==================================================

// 본문 파일 업로드
const uploadedBodyFile = ref<File | null>(null);
const bodyFilePreview = ref<{ name: string; url: string } | null>(null);

// 파일 선택 처리
const handleBodyFile = (event) => {
  const file = event.target.files[0];
  if (!file) return;

  // PDF 파일 형식 검증
  if (file.type !== "application/pdf") {
    $q.notify({
      message: "PDF 파일만 업로드 가능합니다.",
      color: "negative"
    });
    return;
  }

  const target = event.target as HTMLInputElement;
  // uploadedBodyFile.value = target.files[0];
  pdfData.value.uploadedBodyFile = file;

  // 기존 문서가 있으면 교체하도록 처리
  const fileURL = URL.createObjectURL(file);
  // bodyFilePreview.value = { name: file.name, url: fileURL };
  pdfData.value.bodyFilePreview = { name: file.name, url: fileURL };

  // 문서 제목 자동 설정
  title.value = file.name;
};

const removeBodyFile = () => {
  // bodyFilePreview.value = null;
  // title.value = ""; // 파일 제목 초기화
  if (pdfData.value.bodyFilePreview?.url) {
    URL.revokeObjectURL(pdfData.value.bodyFilePreview.url);
  }
  pdfData.value.bodyFilePreview = null;
  pdfData.value.uploadedBodyFile = null;
};

// 페이지 청크 상태 토글
const togglePageChunk = () => {
  pdfData.value.pageChunkYn = pdfData.value.pageChunkYn === 1 ? 0 : 1;
};

// 파일 input 참조
const bodyFileInput = ref<HTMLInputElement | null>(null);
const attachmentFileInput = ref<HTMLInputElement | null>(null);
const imageFileInput = ref<HTMLInputElement | null>(null);

// 버튼 클릭 시 해당 input[type="file"] 트리거
const triggerFileUpload = (type: "knowledge" | "attachment" | "image") => {
  if (type === "knowledge") {
    bodyFileInput.value?.click();
  } else if (type === "attachment") {
    attachmentFileInput.value?.click();
  } else if (type === "image") {
    imageFileInput.value?.click();
  }
};

// ==================================================
// 텍스트 탭 관련 함수
// ==================================================

// 첨부 파일 관리
const uploadedFiles = ref<File[]>([]); // 업로드된 파일 배열
const attachFileKeys = ref<number[]>([]); // 첨부 파일 키 목록
const filePreviews = ref<{ name: string; url: string }[]>([]); // 파일 미리보기

// 첨부 파일 업로드 함수
const handleFileUpload2 = (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  if (!files) return;

  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    // 중복 파일 체크
    if (uploadedFiles.value.some((f) => f.name === file.name)) {
      $q.notify({
        message: "중복된 파일이 있습니다: " + file.name,
        color: "negative"
      });
      continue;
    }

    // 파일 배열에 추가
    uploadedFiles.value.push(file);

    // Blob URL 생성 및 미리보기 추가
    const fileUrl = URL.createObjectURL(file);
    filePreviews.value.push({
      name: file.name,
      url: fileUrl
    });

    console.log("업로드된 파일:", file.name);
  }
};

// 첨부 파일 삭제
const removeFile = (index: number) => {
  uploadedFiles.value.splice(index, 1);
  const removedPreview = filePreviews.value.splice(index, 1);

  if (removedPreview.length > 0) {
    URL.revokeObjectURL(removedPreview[0].url);
  }
};

// 이미지 관리
const images = ref<File[]>([]); // 업로드된 이미지 파일 배열
const imgFileKeys = ref<number[]>([]); // 이미지 파일 키 목록
const previewImages = ref<string[]>([]); // 미리보기 이미지 URL 배열

// 이미지 업로드 함수
const handleImageUpload = (event: Event) => {
  const uploadedFiles = (event.target as HTMLInputElement).files;

  if (uploadedFiles) {
    for (let i = 0; i < uploadedFiles.length; i++) {
      if (images.value.length >= 5) {
        $q.notify({
          message: "이미지는 최대 5개까지 업로드할 수 있습니다.",
          color: "negative"
        });
        return;
      }

      const file = uploadedFiles[i];
      images.value.push(file); // 이미지 파일 저장
      previewImages.value.push(URL.createObjectURL(file)); // 미리보기 URL 생성
    }
  }
};

// 이미지 삭제
const removeImage = (index: number) => {
  images.value.splice(index, 1); // 파일 배열에서 제거
  URL.revokeObjectURL(previewImages.value[index]);
  previewImages.value.splice(index, 1); // 미리보기 배열에서 제거
};

// 바로가기 링크 관리
const linkKeys = ref<number[]>([]);
const linkTitle = ref(""); // 링크 텍스트
const linkUrl = ref(""); // 링크 URL
const previewLinks = ref<{ text: string; url: string }[]>([]); // 미리보기 데이터 배열
const links = ref("");

// 링크 추가 함수
const applyLink = async () => {
  // if (!linkTitle.value || !linkUrl.value) {
  //   console.error("모든 필드를 입력하세요.");
  //   return;
  // }

  // if (previewLinks.value.length >= 5) {
  //   console.error("링크는 최대 5개까지 추가할 수 있습니다.");
  //   return;
  // }

  if (!linkTitle.value || !linkUrl.value) {
    $q.notify({
      message: "링크 텍스트와 URL을 모두 입력해주세요.",
      color: "negative"
    });
    return;
  }

  if (textData.value.previewLinks.length >= 5) {
    $q.notify({
      message: "링크는 최대 5개까지 추가할 수 있습니다.",
      color: "negative"
    });
    return;
  }

  // URL이 'http://' 또는 'https://'로 시작하지 않으면 자동으로 'https://' 추가
  const normalizedUrl =
    linkUrl.value.startsWith("http://") || linkUrl.value.startsWith("https://")
      ? linkUrl.value
      : `https://${linkUrl.value}`;

  try {
    // 링크 생성 API 호출
    const linkData = {
      // 독 업데이트
      // kms_folder_key: store.selectedDocKey, // 현재 선택된 폴더 키
      kms_folder_key: store.selectedFolderKey, // 현재 선택된 폴더 키
      channel_key: Number(props.channelKey), // 현재 채널 키
      link_name: linkTitle.value,
      link_url: normalizedUrl,
      delete_yn: 0
    };

    const result = await createRepoLinkList(linkData);

    // 링크 키 저장
    if (result && result.repo_link_key) {
      // linkKeys.value.push(result.repo_link_key);
      textData.value.linkKeys.push(result.repo_link_key);
    }

    // 미리보기에 추가
    // previewLinks.value.push({
    //   text: linkTitle.value,
    //   url: normalizedUrl
    // });

    // 미리보기에 추가
    textData.value.previewLinks.push({
      text: linkTitle.value,
      url: normalizedUrl
    });

    linkTitle.value = "";
    linkUrl.value = "";
  } catch (error) {
    console.error("링크 생성 중 오류:", error);
    $q.notify({
      message: "링크 추가 중 오류가 발생했습니다.",
      color: "negative"
    });
  }
};

// 링크 삭제
const removeLink = (index: number) => {
  // previewLinks.value.splice(index, 1);
  textData.value.previewLinks.splice(index, 1);
  if (index < textData.value.linkKeys.length) {
    textData.value.linkKeys.splice(index, 1);
  }
};

// ===============================================================
// 파일 업로드 및 API 호출 함수
// ===============================================================

// 파일 업로드 함수 - 파일 리스트 생성 API 호출
const uploadFiles = async (files: File[], fileType: "attach" | "image") => {
  try {
    if (files.length === 0) return [];

    // FormData 생성
    const formData = new FormData();

    // 채널 키 추가
    formData.append("channel_key", props.channelKey.toString());

    // 파일들 추가
    files.forEach((file, index) => {
      formData.append("file_list", file);
    });

    // API 호출
    const response = await createFileList(formData);

    // 파일 키 추가
    if (response && Array.isArray(response)) {
      const fileKeys = response.map((item) => item.file_key);

      if (fileType === "attach") {
        attachFileKeys.value = fileKeys;
      } else {
        imgFileKeys.value = fileKeys;
      }

      return fileKeys;
    }

    return [];
  } catch (error) {
    console.error(`파일 업로드 중 에러:`, error);
    $q.notify({
      message: `${fileType === "attach" ? "첨부 파일" : "이미지"} 업로드 중 오류가 발생했습니다.`,
      color: "negative"
    });
    return [];
  }
};

// ===============================================================
// 지식 추가 로직
// ===============================================================

// 텍스트 지식 추가
const submitTextKnowledge = async () => {
  // 내용 필수 입력 체크
  if (!textData.value.body || textData.value.body.trim() === "") {
    $q.notify({
      message: "원본 내용을 입력해주세요.",
      color: "negative"
    });
    return;
  }
  // 제목, 지식, 키워드가 필요한 경우 체크
  if (
    !textData.value.showGeneratedFields ||
    !textData.value.title ||
    !textData.value.summary ||
    !textData.value.keywords
  ) {
    $q.notify({
      message:
        "AI 자동생성으로 제목, 지식, 키워드, 추천 질문을 먼저 생성해주세요.",
      color: "negative"
    });
    return;
  }

  isGenerating.value = true;

  try {
    // 첨부 파일 업로드 (있는 경우)
    if (uploadedFiles.value.length > 0) {
      // await uploadFiles(uploadedFiles.value, "attach");
      textData.value.attachFileKeys = await uploadFiles(
        uploadedFiles.value,
        "attach"
      );
    }

    // 이미지 파일 업로드 (있는 경우)
    if (images.value.length > 0) {
      // await uploadFiles(images.value, "image");
      textData.value.imgFileKeys = await uploadFiles(images.value, "image");
    }

    const keywordList = textData.value.keywords.split(",").map((k) => k.trim());
    const questLists = textData.value.questList.split(",").map((k) => k.trim());

    const newKnowledge = {
      // 독 업데이트
      // kms_doc_key: store.selectedDocKey,
      kms_folder_key: store.selectedFolderKey,
      channel_key: Number(props.channelKey),
      title: textData.value.title,
      summary: textData.value.summary,
      body: textData.value.body,
      must_text: textData.value.mustText,
      keyword_list: keywordList,
      quest_list: questLists,
      attach_filekey_list: textData.value.attachFileKeys,
      linkkey_list: textData.value.linkKeys,
      img_filekey_list: textData.value.imgFileKeys,
      links: textData.value.previewLinks.map((link) => link.url),
      confirm_yn: 0,
      delete_yn: 0,
      valid_yn: 1
    };

    await createKmsKnow(newKnowledge);

    // 추가 후 지식 목록 새로고침
    // 독 업데이트
    // if (store.selectedDocKey && Number(props.channelKey)) {
    //   await store.loadKnows(Number(props.channelKey), store.selectedDocKey, 1);
    // }
    if (store.selectedFolderKey && Number(props.channelKey)) {
      await store.loadKnows(
        Number(props.channelKey),
        store.selectedFolderKey,
        1
      );
    }

    $q.notify({
      message: "지식이 성공적으로 추가되었습니다.",
      color: "positive"
    });
    closePopup();
  } catch (error) {
    console.error("지식 추가 중 에러:", error);
    $q.notify({
      message: "지식 추가 중 오류가 발생했습니다.",
      color: "negative"
    });
  } finally {
    isGenerating.value = false;
  }
};

// PDF 처리 상태 관리를 위한 새로운 상태 변수들
const pdfProcessing = ref(false);
const pdfProcessProgress = ref(0);
const pdfProcessStatus = ref("준비 중...");
const showProgressPopup = ref(false);
const isProcessComplete = ref(false);

// pdf 지식 추가
const submitFileKnowledge = async () => {
  if (!pdfData.value.uploadedBodyFile) {
    $q.notify({
      message: "PDF 파일을 업로드해주세요.",
      color: "negative"
    });
    return;
  }

  isGenerating.value = true;

  // 페이지 범위 형식 검증 (선택 사항)
  if (pdfData.value.pageRangeStr) {
    const pageRangePattern = /^(\d+(-\d+)?)(,\d+(-\d+)?)*$/;
    if (!pageRangePattern.test(pdfData.value.pageRangeStr)) {
      $q.notify({
        message: "페이지 범위 형식이 올바르지 않습니다. 예: 1-5,8,10-15",
        color: "negative"
      });
      return;
    }
  }

  emit("pdfProcessingStart", {
    // 독 업데이트
    // docKey: store.selectedDocKey,
    folderKey: store.selectedFolderKey,
    file: pdfData.value.uploadedBodyFile,
    pageRangeStr: pdfData.value.pageRangeStr || "",
    pageChunkYn: pdfData.value.pageChunkYn
  });

  // 메인 팝업 닫기
  closePopup();

  try {
    // FormData 구성
    // const formData = new FormData();
    // // 독 업데이트
    // // formData.append("kms_doc_key", String(store.selectedDocKey));
    // formData.append("kms_folder_key", String(store.selectedFolderKey));
    // // formData.append("kms_doc_key", 4639);
    // formData.append("page_range_str", pdfData.value.pageRangeStr || "");
    // formData.append("pdf_file", pdfData.value.uploadedBodyFile);
    // formData.append("page_chunk_yn", String(pdfData.value.pageChunkYn));
    // // formData.append("page_chunk_yn", 0);

    // // API 호출
    // await requestPdfToKnow(formData);

    // 독 업데이트
    // 추가 후 지식 목록 새로고침
    // if (store.selectedDocKey && Number(props.channelKey)) {
    //   await store.loadKnows(Number(props.channelKey), store.selectedDocKey, 1);
    // }
    if (store.selectedFolderKey && Number(props.channelKey)) {
      await store.loadKnows(
        Number(props.channelKey),
        store.selectedFolderKey,
        1
      );
    }

    // 진행 상태 업데이트 (100% - 완료)
    pdfProcessProgress.value = 100;
    pdfProcessStatus.value = "PDF 지식 추가 완료";
    isProcessComplete.value = true;

    $q.notify({
      message: "PDF 지식이 성공적으로 추가되었습니다.",
      color: "positive"
    });

    // 3초 후 진행 팝업 닫기
    setTimeout(() => {
      showProgressPopup.value = false;
      pdfProcessing.value = false;
    }, 3000);

    // closePopup();
  } catch (error) {
    console.error("PDF 지식 추가 중 에러:", error);

    // 에러 상태 업데이트
    pdfProcessProgress.value = 0;
    pdfProcessStatus.value = "오류 발생";
    isProcessComplete.value = true;

    $q.notify({
      message: "PDF 지식 추가 중 오류가 발생했습니다.",
      color: "negative"
    });

    // 3초 후 진행 팝업 닫기
    setTimeout(() => {
      showProgressPopup.value = false;
      pdfProcessing.value = false;
    }, 3000);
  } finally {
    isGenerating.value = false;
  }
};

// 진행 팝업 닫기
const closeProgressPopup = () => {
  showProgressPopup.value = false;
  pdfProcessing.value = false;
};

// 기존 지식 추가 제출
// const submitKnowledge = async () => {
//   // 내용 필수 입력 체크
//   if (!body.value || body.value.trim() === "") {
//     $q.notify({
//       message: "내용을 입력해주세요.",
//       color: "negative"
//     });
//     return;
//   }

//   // 내용만 있고 제목/요약/키워드가 없는 경우
//   if (
//     body.value &&
//     (!title.value || !summary.value || !keywords.value || !questList.value)
//   ) {
//     $q.notify({
//       message:
//         "AI 자동생성으로 제목, 요약, 키워드, 추천 질문을 먼저 생성해주세요.",
//       color: "negative"
//     });
//     return;
//   }

//   try {
//     // 첨부 파일 업로드 (있는 경우)
//     if (uploadedFiles.value.length > 0) {
//       await uploadFiles(uploadedFiles.value, "attach");
//     }

//     // 이미지 파일 업로드 (있는 경우)
//     if (images.value.length > 0) {
//       await uploadFiles(images.value, "image");
//     }

//     const keywordList = keywords.value.split(",").map((k) => k.trim());

//     const questLists = questList.value.split(",").map((k) => k.trim());

//     // 파일 업로드를 위한 FormData 생성
//     const formData = new FormData();

//     // 이미지 파일 추가
//     imageFiles.value.forEach((file) => {
//       formData.append("images[]", file);
//     });

//     // 첨부 파일 추가
//     attachFiles.value.forEach((file) => {
//       formData.append("attachments[]", file);
//     });

//     // const newKnowledge = {
//     //   kms_doc_key: store.selectedFileKey,
//     //   channel_key: Number(props.channelKey), // props로 전달받은 채널키 사용
//     //   title: title.value,
//     //   summary: summary.value,
//     //   body: body.value,
//     //   must_text: mustText.value,
//     //   keyword_list: keywordList,
//     //   attach_filekey_list: attachFileKeys.value,
//     //   linkkey_list: linkKeys.value,
//     //   img_filekey_list: imgFileKeys.value,
//     //   links: links.value.split(",").map((link) => link.trim()),
//     //   confirm_yn: 0, // 기본값 0 (검토 필요)
//     //   delete_yn: 0
//     // };
//     const newKnowledge = {
//       kms_doc_key: store.selectedDocKey,
//       channel_key: Number(props.channelKey), // props로 전달받은 채널키 사용
//       title: title.value,
//       summary: summary.value,
//       body: body.value,
//       must_text: mustText.value,
//       keyword_list: keywordList,
//       quest_list: questLists,
//       attach_filekey_list: attachFileKeys.value,
//       linkkey_list: linkKeys.value,
//       img_filekey_list: imgFileKeys.value,
//       links: links.value.split(",").map((link) => link.trim()),
//       confirm_yn: 0, // 기본값 0 (검토 필요)
//       delete_yn: 0,
//       valid_yn: 1
//     };

//     // FormData에 JSON 데이터 추가
//     formData.append("data", JSON.stringify(newKnowledge));

//     await createKmsKnow(newKnowledge);
//     // 추가 후 지식 목록 새로고침
//     if (store.selectedDocKey && Number(props.channelKey)) {
//       await store.loadKnows(Number(props.channelKey), store.selectedDocKey, 1);
//     }
//     $q.notify({
//       message: "지식이 성공적으로 추가되었습니다.",
//       color: "positive"
//     });
//     closePopup();
//   } catch (error) {
//     console.error("지식 추가 중 에러:", error);
//     $q.notify({
//       message: "지식 추가 중 오류가 발생했습니다.",
//       color: "negative"
//     });
//   }
// };

// ===============================================================
// AI 자동 생성 관련 코드
// ===============================================================

const subOptionYn = ref(false);
const subOptionBtn = () => {
  subOptionYn.value = !subOptionYn.value;
};

export interface preAfunReq {
  // channel_key: number;
  // kms_doc_key: number;
  // kms_know_key: number || null;
  body: string;
  requestType: string;
}

const afunYn = ref(false);

// const createPreAfunBtn = async () => {
//   try {
//     afunYn.value = !afunYn.value;
//     // const requestData = {
//     //   // channel_key: store.currentChannel?.channel_key,
//     //   // kms_doc_key: store.selectedFileKey,
//     //   // kms_know_key: null,
//     //   body: body.value,
//     //   requestType: "A"
//     // } as preAfunReq;

//     // 생성 시작 시 상태 변경
//     isGenerating.value = true;

//     const sourceText = body.value;

//     if (!sourceText || sourceText.trim().length === 0) {
//       $q.notify({
//         message: "요약할 내용을 입력해주세요.",
//         color: "negative"
//       });
//       return;
//     }

//     const requestData = {
//       source_text: sourceText.trim(),
//       channel_key: store.currentChannel?.channel_key.toString()
//     };

//     console.log("전송 데이터:", requestData);

//     const result = await createPreAfunTotalSummary(requestData);
//     const titleResult = await createPreAfunTitle(requestData);

//     if (result && titleResult) {
//       // API 응답에서 데이터 추출
//       title.value = titleResult; // 제목
//       summary.value = result.result.chunk_list[0].summary; // 요약
//       keywords.value = result.result.chunk_list[0].keyword_list.join(", "); // 키워드
//       questList.value = result.result.chunk_list[0].quest_list.join(", "); // 추천질문

//       $q.notify({
//         message: "AI가 제목, 요약, 키워드를 생성했습니다.",
//         color: "positive"
//       });
//     }
//   } catch (error) {
//     console.log("AFUN 처리 중 에러가 발생 ", error);
//     $q.notify({
//       message: "AI 자동 생성 중 오류가 발생했습니다.",
//       color: "negative"
//     });
//   } finally {
//     // 생성 완료 시 상태 복원
//     isGenerating.value = false;
//   }
// };
// const createPreAfunBtn = async () => {
//   try {
//     afunYn.value = !afunYn.value;
//     isGenerating.value = true;
//     // showGeneratedFields.value = false; // 생성 시작 시 필드 숨기기
//     textData.value.showGeneratedFields = false;

//     const sourceText = textData.value.body;

//     if (!sourceText || sourceText.trim().length === 0) {
//       $q.notify({
//         message: "AI가 자동 생성하기 위해 내용을 입력해주세요.",
//         color: "negative"
//       });
//       return;
//     }

//     const requestData = {
//       source_text: sourceText.trim(),
//       channel_key: store.currentChannel?.channel_key.toString()
//     };

//     console.log("전송 데이터:", requestData);

//     const result = await createPreAfunTotalSummary(requestData);
//     console.log("createPreAfunTotalSummary 실행 후 결과:", result);

//     if (
//       !result ||
//       !result.result ||
//       !Array.isArray(result.result.chunk_list) ||
//       result.result.chunk_list.length === 0
//     ) {
//       throw new Error("서버 응답에 chunk_list가 없습니다.");
//     }

//     // const titleResult = await createPreAfunTitle(requestData);
//     // console.log("createPreAfunTitle 실행 후 결과:", titleResult);

//     // if (!titleResult) {
//     //   throw new Error("createPreAfunTitle의 응답이 없습니다.");
//     // }

//     const chunk = result.result.chunk_list[0] ?? {};

//     // API 응답에서 데이터 추출
//     // title.value = titleResult; // 제목
//     // summary.value = chunk.summary ?? ""; // 요약
//     // keywords.value = Array.isArray(chunk.keyword_list)
//     //   ? chunk.keyword_list.join(", ")
//     //   : ""; // 키워드
//     // questList.value = Array.isArray(chunk.quest_list)
//     //   ? chunk.quest_list.join(", ")
//     //   : ""; // 추천질문

//     // // 생성이 완료된 후 필드 표시
//     // showGeneratedFields.value = true;

//     // API 응답에서 데이터 추출
//     textData.value.title = titleResult;
//     textData.value.summary = chunk.summary ?? "";
//     textData.value.keywords = Array.isArray(chunk.keyword_list)
//       ? chunk.keyword_list.join(", ")
//       : "";
//     textData.value.questList = Array.isArray(chunk.quest_list)
//       ? chunk.quest_list.join(", ")
//       : "";

//     // 생성이 완료된 후 필드 표시
//     textData.value.showGeneratedFields = true;

//     $q.notify({
//       message: "AI가 제목, 요약, 키워드, 추천 질문을 생성하였습니다.",
//       color: "positive"
//     });
//   } catch (error) {
//     $q.notify({
//       message: `AI 자동 생성 중 오류가 발생하였습니다.`,
//       color: "negative"
//     });
//   } finally {
//     isGenerating.value = false;
//   }
// };
const createPreAfunBtn = async () => {
  try {
    afunYn.value = !afunYn.value;
    isGenerating.value = true;
    // showGeneratedFields.value = false; // 생성 시작 시 필드 숨기기
    textData.value.showGeneratedFields = false;

    const sourceText = textData.value.body;

    if (!sourceText || sourceText.trim().length === 0) {
      $q.notify({
        message: "AI가 자동 생성하기 위해 원본 내용을 입력해주세요.",
        color: "negative"
      });
      return;
    }

    const requestData = {
      source_text: sourceText.trim(),
      channel_key: store.currentChannel?.channel_key.toString()
    };

    console.log("전송 데이터:", requestData);

    const result = await createPreAfunTotalSummary(requestData);
    console.log("createPreAfunTotalSummary 실행 후 결과:", result);

    if (
      !result ||
      !result.result ||
      !Array.isArray(result.result.chunk_list) ||
      result.result.chunk_list.length === 0
    ) {
      throw new Error("서버 응답에 chunk_list가 없습니다.");
    }

    // const titleResult = await createPreAfunTitle(requestData);
    // console.log("createPreAfunTitle 실행 후 결과:", titleResult);

    // if (!titleResult) {
    //   throw new Error("createPreAfunTitle의 응답이 없습니다.");
    // }

    const chunk = result.result.chunk_list[0] ?? {};

    // API 응답에서 데이터 추출
    // title.value = titleResult; // 제목
    // summary.value = chunk.summary ?? ""; // 요약
    // keywords.value = Array.isArray(chunk.keyword_list)
    //   ? chunk.keyword_list.join(", ")
    //   : ""; // 키워드
    // questList.value = Array.isArray(chunk.quest_list)
    //   ? chunk.quest_list.join(", ")
    //   : ""; // 추천질문

    // // 생성이 완료된 후 필드 표시
    // showGeneratedFields.value = true;

    // API 응답에서 데이터 추출
    // textData.value.title = titleResult;
    textData.value.title = chunk.title ?? "";
    textData.value.summary = chunk.summary ?? "";
    textData.value.keywords = Array.isArray(chunk.keyword_list)
      ? chunk.keyword_list.join(", ")
      : "";
    textData.value.questList = Array.isArray(chunk.quest_list)
      ? chunk.quest_list.join(", ")
      : "";

    // 생성이 완료된 후 필드 표시
    textData.value.showGeneratedFields = true;

    $q.notify({
      message: "AI가 제목, 지식, 키워드, 추천 질문을 생성하였습니다.",
      color: "positive"
    });
  } catch (error) {
    $q.notify({
      message: `AI 자동 생성 중 오류가 발생하였습니다.`,
      color: "negative"
    });
  } finally {
    isGenerating.value = false;
  }
};
</script>

<template>
  <!-- <div class="modal-overlay" @click="closePopup"> -->
  <div class="modal-overlay">
    <!-- <div class="view-popup"> -->
    <div class="modal-content" @click.stop>
      <div class="modal-header">
        <p style="font-weight: bold; font-size: 20px">지식 추가</p>
        <!-- <button class="close-btn" @click="closePopup">X</button> -->
        <div @click="closePopup" class="cursorP">
          <CloseIcon />
        </div>
      </div>

      <div class="modal-body">
        <!-- 텍스트/파일 업로드 탭 메뉴 -->
        <div class="tab-menu">
          <button
            class="tab-button"
            :class="{ active: activeTab === 'text' }"
            @click="activeTab = 'text'"
          >
            <TextIcon /> 텍스트
          </button>
          <button
            class="tab-button"
            :class="{ active: activeTab === 'pdf' }"
            @click="activeTab = 'pdf'"
          >
            <FileDownload /> PDF
          </button>
        </div>

        <!-- 텍스트 지식 입력 폼 -->
        <form v-if="activeTab === 'text'" @submit.prevent="submitTextKnowledge">
          <!-- <form @submit.prevent="submitKnowledge"> -->
          <div class="main-know-block">
            <!-- <div class="main-know-title">메인 지식</div> -->
            <div class="form-group">
              <label for="body">
                원본 내용
                <!-- <span class="afun-description">
                  지식이 될 내용을 입력합니다.
                </span> -->
              </label>
              <textarea
                id="body"
                v-model="textData.body"
                class="body-textarea"
                placeholder="내용을 입력하세요"
                required
              ></textarea>
            </div>
          </div>

          <!-- AFUN 처리 -->
          <!-- <div v-if="!showGeneratedFields" class="form-group afunWrap"> -->
          <div class="afunWrap-container">
            <!-- 상단 부분 (항상 표시) -->
            <div class="afunWrap">
              <!-- <div> -->
              <label class="afun-label">
                <!-- AI 자동 생성 -->
                AFUN
                <span class="afun-description">
                  내용 입력 시 분석 후 자동으로 제목, 지식, 키워드, 추천 질문을
                  생성합니다.
                </span>
              </label>
              <!-- </div> -->
              <button
                @click="createPreAfunBtn"
                type="button"
                :disabled="isGenerating"
                class="ai-button"
              >
                {{ isGenerating ? "생성 중..." : "AI 자동 생성" }}
              </button>
              <div v-if="isGenerating" class="spinner-overlay">
                <div class="spinner"></div>
                <p>AI가 제목, 지식, 키워드, 추천 질문을 생성 중입니다...</p>
              </div>
            </div>

            <!-- 확장 부분 (생성 후 표시) -->
            <!-- AFUN 시 생성된 필드들 -->
            <transition name="expand">
              <div
                v-if="textData.showGeneratedFields"
                class="afunWrap-extended"
              >
                <!-- <div class="generated-fields-title">AI 생성</div> -->
                <div class="form-group form-group-left">
                  <label
                    class="title-top"
                    for="title"
                    style="border-top: 1px solid #b2f4e3"
                    >제목</label
                  >
                  <input
                    id="title"
                    v-model="textData.title"
                    type="text"
                    placeholder="제목을 입력하세요"
                    required
                  />
                </div>

                <div class="form-group form-group-left">
                  <label for="summary">지식</label>
                  <textarea
                    id="summary"
                    v-model="textData.summary"
                    placeholder="내용을 요약해서 입력하세요"
                    required
                  ></textarea>
                </div>

                <div class="form-group form-group-left">
                  <label for="keywords">키워드</label>
                  <input
                    id="keywords"
                    v-model="textData.keywords"
                    type="text"
                    placeholder="키워드를 쉼표(,)로 구분하여 입력하세요"
                  />
                </div>

                <div class="form-group form-group-left">
                  <label for="questList">추천 질문</label>
                  <input
                    id="questList"
                    v-model="textData.questList"
                    type="text"
                    placeholder="추천질문을 쉼표(,)로 구분하여 입력하세요"
                  />
                </div>
                <!-- <div class="form-group">
                  <label for="title">제목</label>
                  <input
                    v-if="isGenerating"
                    id="title"
                    v-model="title"
                    type="text"
                    placeholder="자동 생성중입니다..."
                    required
                    class="generating"
                  />
                  <input
                    v-else
                    id="title"
                    v-model="title"
                    type="text"
                    placeholder="제목을 입력하세요"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="summary">요약</label>
                  <textarea
                    v-if="isGenerating"
                    id="summary"
                    v-model="summary"
                    placeholder="자동 생성중..."
                    required
                    class="generating"
                  ></textarea>
                  <textarea
                    v-else
                    id="summary"
                    v-model="summary"
                    placeholder="내용을 요약해서 입력하세요"
                    required
                  ></textarea>
                </div>

                <div class="form-group">
                  <label for="keywords">키워드</label>
                  <input
                    v-if="isGenerating"
                    id="keywords"
                    v-model="keywords"
                    type="text"
                    placeholder="자동 생성중..."
                    class="generating"
                  />
                  <input
                    v-else
                    id="keywords"
                    v-model="keywords"
                    type="text"
                    placeholder="키워드를 쉼표(,)로 구분하여 입력하세요"
                  />
                </div>

                <div class="form-group">
                  <label for="questList">추천 질문</label>
                  <input
                    v-if="isGenerating"
                    id="keywords"
                    v-model="questList"
                    type="text"
                    placeholder="자동 생성중..."
                    class="generating"
                  />
                  <input
                    v-else
                    id="keywords"
                    v-model="questList"
                    type="text"
                    placeholder="추천질문을 쉼표(,)로 구분하여 입력하세요"
                  />
                </div> -->
              </div>
            </transition>
          </div>

          <!-- 부가 정보 패널 -->
          <transition name="slide-fade">
            <div class="additional-info-block">
              <div class="additional-header">
                <label class="additional-label">
                  부가 정보
                  <span class="afun-description">
                    지식의 부가 정보를 설정합니다.
                  </span>
                </label>
                <button
                  type="button"
                  @click="subOptionBtn"
                  class="option-button"
                >
                  {{ subOptionYn ? "↑ 접기" : "↓ 펼치기" }}
                </button>
              </div>

              <transition name="expand">
                <div v-if="subOptionYn" class="additional-content">
                  <!-- 필수 표시 -->
                  <div class="form-group form-group-left">
                    <label for="must-text">필수 표시</label>
                    <textarea
                      id="must-text"
                      v-model="textData.mustText"
                      placeholder="필수 표시 내용을 입력하세요"
                    ></textarea>
                  </div>
                  <!-- 첨부 파일 업로드 -->
                  <div class="form-group form-group-left">
                    <div class="flexStlye">
                      <label>첨부 파일</label>
                      <button
                        type="button"
                        class="apply-btn"
                        @click="triggerFileUpload('attachment')"
                      >
                        추가
                      </button>
                    </div>
                    <input
                      v-show="false"
                      ref="attachmentFileInput"
                      id="file-upload-attachment"
                      type="file"
                      @change="handleFileUpload2"
                      multiple
                    />
                    <div class="file-preview">
                      <ul>
                        <li v-for="(file, index) in filePreviews" :key="index">
                          <a :href="file.url" target="_blank" download>{{
                            file.name
                          }}</a>
                          <button class="remove-btn" @click="removeFile(index)">
                            X
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!-- 이미지 업로드 -->
                  <div class="form-group form-group-left">
                    <div class="flexStlye">
                      <label>첨부 이미지</label>
                      <button
                        type="button"
                        class="apply-btn"
                        @click="triggerFileUpload('image')"
                      >
                        추가
                      </button>
                    </div>
                    <input
                      v-show="false"
                      ref="imageFileInput"
                      id="image-upload"
                      type="file"
                      @change="handleImageUpload"
                      accept="image/*"
                      multiple
                    />
                    <div class="image-preview">
                      <div class="preview-grid">
                        <div
                          class="preview-item"
                          v-for="(image, index) in previewImages"
                          :key="index"
                        >
                          <img :src="image" alt="Preview Image" />
                          <button
                            class="remove-btn"
                            @click="removeImage(index)"
                          >
                            X
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 바로가기 링크 -->
                  <div class="form-group form-group-left">
                    <div class="menuWrap">
                      <div>
                        <label for="link-text">바로가기 링크</label>
                      </div>
                      <div>
                        <button
                          class="apply-btn"
                          @click="applyLink"
                          type="button"
                        >
                          추가
                        </button>
                      </div>
                    </div>
                    <input
                      id="link-text"
                      type="text"
                      v-model="linkTitle"
                      placeholder="링크 텍스트를 입력하세요"
                    />
                    <input
                      id="link-url"
                      type="url"
                      v-model="linkUrl"
                      placeholder="URL을 입력하세요"
                    />

                    <div
                      v-if="textData.previewLinks.length"
                      class="link-preview"
                    >
                      <div class="linkListWrap">
                        <div
                          v-for="(link, index) in textData.previewLinks"
                          :key="index"
                        >
                          <a :href="link.url" target="_blank">{{
                            link.text
                          }}</a>
                          <button class="remove-btn" @click="removeLink(index)">
                            X
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </transition>
        </form>

        <!-- pdf 지식 업로드 폼 -->
        <form v-if="activeTab === 'pdf'" @submit.prevent="submitFileKnowledge">
          <div class="main-know-block">
            <!-- <div class="main-know-title">메인 지식</div> -->
            <div class="form-group">
              <!-- <div class="flexStlye knowFile"> -->
              <div class="flexStlye">
                <label>지식 파일</label>
                <button
                  type="button"
                  class="apply-btn"
                  @click="triggerFileUpload('knowledge')"
                >
                  추가
                </button>
              </div>
              <input
                v-show="false"
                ref="bodyFileInput"
                id="file-upload-knowledge"
                type="file"
                @change="handleBodyFile"
                accept="application/pdf"
                required
              />
              <div class="file-preview">
                <ul v-if="pdfData.bodyFilePreview">
                  <li>
                    <a
                      class="file-name"
                      :href="pdfData.bodyFilePreview.url"
                      target="_blank"
                      download
                    >
                      {{ pdfData.bodyFilePreview.name }}
                    </a>
                    <button class="remove-btn" @click="removeBodyFile">
                      X
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <!-- PDF 페이지 범위 입력 -->
            <div class="form-group">
              <label for="page-range">
                페이지 범위
                <span class="afun-description">
                  지식으로 추가할 페이지를 설정합니다.
                </span>
              </label>
              <input
                id="page-range"
                v-model="pdfData.pageRangeStr"
                type="text"
                placeholder="예: 1-5,8,10-15"
              />
              <!-- <div class="help-text">비워두면 전체 페이지가 사용됩니다.</div> -->
            </div>

            <!-- 페이지 청크 사용 여부 -->
            <div class="form-group chunk-option">
              <label class="switch-label">
                페이지 청크 사용
                <span class="afun-description">
                  페이지를 개별 지식 단위로 구분합니다.
                </span>
              </label>
              <div class="switch-container">
                <label
                  class="SwitchLabel"
                  for="multi-query-mode"
                  title="페이지 청크 사용 유무"
                >
                  <SwitchRoot
                    class="SwitchRoot"
                    id="muliti-query-mode"
                    :checked="pdfData.pageChunkYn === 1"
                    @update:checked="togglePageChunk"
                  >
                    <SwitchThumb class="SwitchThumb" />
                  </SwitchRoot>
                  <p>{{ pdfData.pageChunkYn === 1 ? "사용" : "미사용" }}</p>
                </label>

                <!-- <SwitchRoot
                  class="switch-root"
                  :checked="pdfData.pageChunkYn === 1"
                  @update:checked="togglePageChunk"
                >
                  <SwitchThumb class="switch-thumb" />
                </SwitchRoot>
                <span class="switch-status">{{
                  pdfData.pageChunkYn === 1 ? "사용" : "미사용"
                }}</span> -->
              </div>
            </div>
          </div>

          <!-- AFUN 처리 (파일 업로드 후 자동 생성) -->
          <!-- <div class="form-group afunWrap">
            <div>
              <label>AI 자동 생성</label>
              <p>* 파일 분석 후 자동으로 제목, 요약, 키워드를 생성합니다</p>
            </div>
            <button
              @click="createPreAfunBtn"
              type="button"
              :disabled="isGenerating"
              class="ai-button"
            >
              {{ isGenerating ? "생성 중..." : "자동생성 시작" }}
            </button>
          </div> -->

          <!-- AFUN 시 생성된 필드들 -->
          <!-- <div
            v-if="bodyFilePreview && showGeneratedFields"
            class="generated-fields-block"
          >
            <div class="form-group">
              <label for="title-file">제목</label>
              <input
                v-if="isGenerating"
                id="title-file"
                v-model="title"
                type="text"
                placeholder="자동 생성중입니다..."
                required
                class="generating"
              />
              <input
                v-else
                id="title-file"
                v-model="title"
                type="text"
                placeholder="제목을 입력하세요"
                required
              />
            </div>

            <div class="form-group">
              <label for="summary-file">요약</label>
              <textarea
                v-if="isGenerating"
                id="summary-file"
                v-model="summary"
                placeholder="자동 생성중..."
                required
                class="generating"
              ></textarea>
              <textarea
                v-else
                id="summary-file"
                v-model="summary"
                placeholder="요약을 입력하세요"
                required
              ></textarea>
            </div>

            <div class="form-group">
              <label for="keywords-file">키워드</label>
              <input
                v-if="isGenerating"
                id="keywords-file"
                v-model="keywords"
                type="text"
                placeholder="자동 생성중..."
                class="generating"
              />
              <input
                v-else
                id="keywords-file"
                v-model="keywords"
                type="text"
                placeholder="키워드를 쉼표(,)로 구분하여 입력하세요"
              />
            </div>

            <div class="form-group">
              <label for="questList-file">추천 질문</label>
              <input
                v-if="isGenerating"
                id="questList-file"
                v-model="questList"
                type="text"
                placeholder="자동 생성중..."
                class="generating"
              />
              <input
                v-else
                id="questList-file"
                v-model="questList"
                type="text"
                placeholder="추천질문을 쉼표(,)로 구분하여 입력하세요"
              />
            </div>
          </div> -->

          <!-- 부가 정보 토글 버튼 (파일 모드) -->
          <!-- <div class="form-group subOptionWrap" v-if="bodyFilePreview">
            <div>
              <label for="must-text-file">부가 정보</label>
              <p>* 필수표시, 첨부파일, 링크 등 부가정보를 설정합니다.</p>
            </div>
            <button type="button" @click="subOptionBtn">
              {{ subOptionYn ? "↑ 접기" : "↓ 펼치기" }}
            </button>
          </div> -->

          <!-- 부가 정보 패널 (파일 모드) -->
          <!-- <div v-if="subOptionYn && bodyFilePreview"> -->
          <!-- <div class="additional-info-block">
            <div class="form-group">
              <label for="must-text-file">필수 표시</label>
              <textarea
                id="must-text-file"
                v-model="mustText"
                placeholder="필수 표시 내용을 입력하세요"
              ></textarea>
            </div>

            <div class="form-group">
              <div class="flexStlye">
                <label>참고 파일</label>
                <button
                  type="button"
                  class="apply-btn"
                  @click="triggerFileUpload('attachment')"
                >
                  추가
                </button>
              </div>
              <input
                v-show="false"
                ref="attachmentFileInput"
                id="file-upload-attachment-file"
                type="file"
                @change="handleFileUpload2"
                multiple
              />
              <div class="file-preview">
                <ul>
                  <li v-for="(file, index) in filePreviews" :key="index">
                    <a :href="file.url" target="_blank" download>{{
                      file.name
                    }}</a>
                    <button class="remove-btn" @click="removeFile(index)">
                      X
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div class="form-group">
              <div class="flexStlye">
                <label>참고 이미지</label>
                <button
                  type="button"
                  class="apply-btn"
                  @click="triggerFileUpload('image')"
                >
                  추가
                </button>
              </div>
              <input
                v-show="false"
                ref="imageFileInput"
                id="image-upload-file"
                type="file"
                @change="handleImageUpload"
                accept="image/*"
                multiple
              />
              <div class="image-preview">
                <div class="preview-grid">
                  <div
                    class="preview-item"
                    v-for="(image, index) in previewImages"
                    :key="index"
                  >
                    <img :src="image" alt="Preview Image" />
                    <button class="remove-btn" @click="removeImage(index)">
                      X
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="menuWrap">
                <div>
                  <label for="link-text-file">바로가기 링크</label>
                </div>
                <div>
                  <button class="apply-btn" @click="applyLink" type="button">
                    추가
                  </button>
                </div>
              </div>
              <input
                id="link-text-file"
                type="text"
                v-model="linkTitle"
                placeholder="링크 텍스트를 입력하세요"
              />
              <input
                id="link-url-file"
                type="url"
                v-model="linkUrl"
                placeholder="URL을 입력하세요"
              />

              <div v-if="previewLinks.length" class="link-preview">
                <div class="linkListWrap">
                  <div v-for="(link, index) in previewLinks" :key="index">
                    <a :href="link.url" target="_blank">{{ link.text }}</a>
                    <button class="remove-btn" @click="removeLink(index)">
                      X
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </form>
      </div>
      <div class="form-actions">
        <button
          type="submit"
          class="submit-btn"
          @click="
            activeTab === 'text' ? submitTextKnowledge() : submitFileKnowledge()
          "
          :disabled="isGenerating"
        >
          {{ isGenerating ? "생성 중..." : "추가" }}
        </button>
        <button type="button" class="cancel-btn" @click="closePopup">
          닫기
        </button>
      </div>
      <!-- <div class="form-actions">
        <button type="submit" class="submit-btn" @click="submitKnowledge">
          추가
        </button>
        <button type="button" class="cancel-btn" @click="closePopup">
          취소
        </button>
        <button type="button" class="cancel-btn" @click="closePopup">
          닫기
        </button>
      </div> -->
    </div>
  </div>
  <!-- PDF 처리 진행 상태 팝업 -->
  <teleport to="body">
    <div
      v-if="showProgressPopup"
      class="progress-popup-overlay"
      @click.self="closeProgressPopup"
    >
      <div class="progress-popup">
        <div class="progress-header">
          <span
            >PDF 지식 추가 {{ isProcessComplete ? "완료" : "진행 중" }}</span
          >
          <button @click="closeProgressPopup" class="close-progress-btn">
            X
          </button>
        </div>
        <div class="progress-body">
          <div class="progress-status">{{ pdfProcessStatus }}</div>
          <div class="progress-bar-container">
            <div
              class="progress-bar"
              :style="{ width: `${pdfProcessProgress}%` }"
              :class="{ complete: isProcessComplete }"
            ></div>
          </div>
          <div class="progress-percentage">{{ pdfProcessProgress }}%</div>
        </div>
        <div v-if="isProcessComplete" class="progress-actions">
          <button @click="closeProgressPopup" class="ok-btn">확인</button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  max-width: 90vw;
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  min-height: 600px;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-body {
  /* overflow-y: auto; */
  overflow-y: scroll;
  max-height: calc(100vh - 150px); /* 상하단 여백 포함한 높이 */
  padding: 15px 10px 5px 0px;
  height: 680px;
  scrollbar-width: thin; /* Firefox */
}

.modal-header {
  /* height: 60px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 16px 0;
  margin-bottom: 4px;
  border-bottom: 1px solid #ddd;
}

.title-top {
  padding-top: 10px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group-left {
  margin-left: 5px !important;
}

.body-textarea {
  min-height: 200px;
}

textarea {
  min-height: 50px;
  resize: vertical;
}

.form-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.submit-btn {
  background-color: #51c2b8;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.submit-btn:hover {
  background-color: #18a498;
}

.cancel-btn {
  background-color: #f5f5f5;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.close-button {
  background-color: #f5f5f5;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.cancel-btn:hover {
  background-color: #ddd;
}

.subOptionWrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* background-color: #eefffb; */
  /* border-top: 20px solid rgb(232, 232, 232); */
  padding: 10px;
}

.subOptionWrap button {
  background-color: white;
  box-shadow: none;
  margin: 10px 0px;
  font-weight: 800;
}

.afunWrap-container {
  margin: 15px 0 25px 0;
}

.afunWrap {
  display: flex;
  justify-content: space-between;
  /* background-color: #eefffb; */
  /* background-color: #eefffb; */
  /* border: 4px solid #b2f4e3; */
  /* border-radius: 5px; */
  /* padding: 10px; */
  background-color: #f9fffd;
  border: 1px solid #b2f4e3;
  border-radius: 8px;
  padding: 16px 15px;
  /* margin: 15px 0 25px 0; */
  /* 하단 border-radius를 조정 (확장될 때) */
  border-bottom-left-radius: v-bind('showGeneratedFields ? "0" : "8px"');
  border-bottom-right-radius: v-bind('showGeneratedFields ? "0" : "8px"');
}

.afunWrap-extended {
  background-color: #f9fffd;
  border: 1px solid #b2f4e3;
  border-top: none; /* 상단 border 제거 */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0px 15px 16px 15px;
  margin-top: -1px; /* 경계선 겹침 방지 */
}

/* 확장 애니메이션 */
.expand-enter-active,
.expand-leave-active {
  transition: all 0.5s ease;
  overflow: hidden;
}

.expand-enter-from,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.expand-enter-to,
.expand-leave-from {
  max-height: 1000px;
  opacity: 1;
}

.afun-label-container {
  flex: 1;
}

.afun-label {
  display: flex !important;
  /* flex-direction: column !important; */
  align-items: center !important;
  /* justify-content: center !important; */
  font-weight: 700;
  /* font-size: 15px; */
  /* color: #222; */
  /* text-align: center !important; */
  margin: 0 !important;
}

.additional-label {
  display: flex !important;
  align-items: center !important;
  font-weight: 700;
  /* margin-bottom: 10px !important; */
}

.option-button {
  background-color: #d6d6d6;
  /* color: #333; */
  /* border: 1px solid #ccc; */
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px !important;
  font-weight: 700;
  height: 30px;
}

.option-button:hover {
  background-color: #c3c3c3;
}

.additional-content {
  margin-top: 15px;
  border-top: 1px solid #eee;
  padding-top: 15px;
}

@media (max-width: 530px) {
  .afun-description {
    max-width: 150px;
    padding-right: 10px;
  }
}

.afun-description {
  /* margin-left: 10px;
  margin-right: 10px; */
  padding-left: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #555;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.afunWrap button {
  background-color: #b2f4e3;
  /* font-weight: 700; */
  height: 30px;
  place-self: center;
}

.afunWrap button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
  position: relative;
}

.generation-button-container {
  position: relative;
  display: flex;
  align-items: center;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #51c2b8 !important;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-overlay p {
  color: white;
  margin-top: 15px;
  font-size: 18px;
}

.scroll {
  overflow-y: auto;
  max-height: calc(100vh - 150px); /* 상하단 여백 포함한 높이 */
  height: 590px;
}

/* slide-fade-enter-active와 leave-active에 동일한 트랜지션 적용 */
.slide-fade-enter-active {
  transition: all 0.3s ease;
  position: relative;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
  position: relative;
}

/* 요소가 나타날 때 */
.slide-fade-enter {
  transform: translateY(-20px);
  opacity: 1;
}

/* 요소가 사라질 때 */
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

/* ======================================================================== 래포 부분  */
/* 첨부 파일 업로드 스타일 */
.file-preview {
  margin-top: 10px;
}

.file-preview ul {
  list-style-type: none;
  padding: 0;
}

.file-preview li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.file-preview a {
  flex: 1;
  text-decoration: none;
  color: #007bff;
}

.file-preview a:hover {
  text-decoration: underline;
}

.remove-btn {
  margin-left: 10px;
  background-color: rgb(73, 73, 73);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 3px 5px;
  cursor: pointer;
  min-width: 30px !important;
}

.remove-btn:hover {
  background-color: rgb(34, 34, 34);
}

/* 이미지 업로드 스타일 */
.image-preview {
  margin-top: 10px;
}

.preview-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.preview-item {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.preview-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-item .remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.preview-item .remove-btn:hover {
  background-color: red;
}

/* 바로가기 링크 스타일 */
.link-preview {
  margin-top: 10px;
}

.link-preview ul {
  list-style-type: none;
  padding: 0;
}

.link-preview li {
  margin-bottom: 5px;
}

.link-preview a {
  color: #007bff;
  text-decoration: none;
}

.link-preview a:hover {
  text-decoration: underline;
}

.menuWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.linkListWrap {
  display: flex;
  gap: 10px;
}

.ai-button {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.ai-button:hover {
  background-color: #92decb;
}

.generating {
  background-color: #f1f3f2;
}

.flexStlye {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.knowFile {
  margin-bottom: 25px !important;
}
.upload-btn {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
  /* font-size: 14px; */
  font-size: 12px !important;
}

.upload-btn:hover {
  background-color: #e0e0e0;
}

.apply-btn {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  /* font-size: 14px; */
  font-size: 12px !important;
}

.apply-btn:hover {
  background-color: #e0e0e0;
}

.close-btn {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

/* 탭 메뉴 스타일 */
.tab-menu {
  background-color: white !important;
  border-bottom: 0.1px solid rgb(185, 185, 185);
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.tab-button {
  min-width: 100px;
  font-size: 14px;
  padding: 8px 15px;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 28px !important;
}

.tab-button.active {
  background-color: #595959;
  color: white;
}

#link-url {
  margin-top: 10px;
}

#link-url-file {
  margin-top: 10px;
}

.main-know-block {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px 15px;
  margin: 15px 0 10px 0;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  position: relative;
}

.main-know-title {
  position: absolute;
  top: -14px;
  right: 20px;
  background-color: #f3f3f3;
  padding: 4px 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #444;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.generated-fields-block {
  border: 1px solid #b2f4e3;
  border-radius: 8px;
  padding: 16px 20px;
  margin: 15px 0 25px 0;
  background-color: #f9fffd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  position: relative;
}

.generated-fields-title {
  position: absolute;
  top: -14px;
  right: 20px;
  background-color: #b2f4e3;
  padding: 4px 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.additional-info-block {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px 15px;
  margin: 15px 0 5px 0;
  background-color: #fafafa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  position: relative;
}

.additional-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.additional-info-title {
  position: absolute;
  top: -14px;
  right: 20px;
  background-color: #e0e0e0;
  padding: 4px 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #444;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* 슬라이드 페이드 애니메이션 개선 */
.slide-fade-enter-active {
  transition: all 0.5s ease;
  overflow: hidden;
  max-height: 1000px;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  overflow: hidden;
  max-height: 0;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
  max-height: 0;
}

/* 페이지 청크 스위치 스타일 */
.chunk-option {
  margin: 20px 0 5px 0;
}

.switch-label {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-bottom: 10px;
}

.switch-container {
  display: flex !important;
  align-items: center;
  gap: 10px;
}

.switch-root {
  width: 42px;
  height: 25px;
  background-color: rgba(128, 128, 128, 0.3);
  border-radius: 100px !important;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.switch-root[data-state="checked"] {
  background-color: #51c2b8;
}

.SwitchLabel p {
  padding-left: 10px;
  font-size: 1rem;
}

.SwitchLabel {
  display: flex !important;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 0 !important;
  width: 100%;
}
:deep(.SwitchRoot) {
  width: 42px;
  height: 25px;
  border-radius: 9999px;
  position: relative;
  box-shadow: none;
  -webkit-tap-highlight-color: rgb(84, 84, 84);
  padding: 0;
  margin: 0;
  &:focus {
    box-shadow: 0 0 0 2px rgb(84, 84, 84);
  }
  &[data-state="checked"] {
    background-color: rgb(84, 84, 84);
  }
}

:deep(.SwitchThumb) {
  display: block;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: none;
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;
  box-shadow: 0px 0px 1px 0px black;
  &[data-state="checked"] {
    transform: translateX(28px);
    background-color: #939393;
    border: 0.5px solid gray;
    box-shadow: 0px 0px 1px 0px black;
  }
}

:deep(.DialogRoot),
:deep(.DialogContent),
:deep([role="dialog"]),
:deep(.ReferCommonPopLayer),
:deep(.popLayer) {
  outline: none !important;
  box-shadow: none !important;
}

.switch-status {
  font-size: 14px;
  color: #666;
}

.help-text {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
  font-style: italic;
}

/* 스피너 오버레이 스타일 */
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #51c2b8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-overlay p {
  color: white;
  margin-top: 15px;
  font-size: 18px;
}

/* 진행 상태 팝업 스타일 */
.progress-popup-overlay {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;
}

.progress-popup {
  width: 320px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  animation: slide-up 0.3s ease-out;
  pointer-events: auto;
}

@keyframes slide-up {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.progress-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #eee;
}

.progress-header span {
  font-weight: 600;
  font-size: 14px;
  color: #333;
}

.close-progress-btn {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #666;
}

.progress-body {
  padding: 15px;
}

.progress-status {
  font-size: 13px;
  color: #555;
  margin-bottom: 10px;
}

.progress-bar-container {
  height: 8px;
  background-color: #eee;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 8px;
}

.progress-bar {
  height: 100%;
  background-color: #51c2b8;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.progress-bar.complete {
  background-color: #4caf50;
}

.progress-percentage {
  text-align: right;
  font-size: 12px;
  color: #666;
}

.progress-actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px 15px;
}

.ok-btn {
  background-color: #51c2b8;
  color: white;
  border: none;
  padding: 6px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
}

.ok-btn:hover {
  background-color: #45a69f;
}
</style>
